import { createStore } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'
import config from '../config'

import reducers from './reducers'

let store
if (!config.dev) {
  store = createStore(reducers, composeWithDevTools())
} else {
  store = createStore(reducers)
}
export default store
