import React, { useState } from 'react'
import { GlobalModal } from '../Modal'
import Checkbox from '@mui/material/Checkbox'

function ExportModal({ open, close, getData }) {

  const [wholeClass, setWholeClass] = useState(false)
  const [individual, setIndividual] = useState(false)

  const confirmHandler = () => {
    close()
    getData(wholeClass, individual)
  }

  return (
    <GlobalModal heading="Export Report(s)" open={open} close={close}>
      <div style={{ marginTop: '1rem' }} >
        <div className='group'>
          <Checkbox
            sx={{
              '&.Mui-checked': {
                color: '#414141',
              },
            }}
            value={wholeClass}
            onClick={(e) => setWholeClass(!wholeClass)}
          />
          <span>Whole class performance (.pdf)</span>
        </div>
        <div className='group'>
          <Checkbox
            sx={{
              '&.Mui-checked': {
                color: '#414141',
              },
            }}
            value={individual}
            onClick={(e) => setIndividual(!individual)}
          />
          <span>Individual reports (.zip)</span>
        </div>
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2rem'
      }} >
        <button onClick={confirmHandler} className='btn-analytics'>
          Confirm
        </button>
        <button onClick={close} className='btn-cancel' style={{
          marginLeft: '1rem'
        }} >
          Cancel
        </button>
      </div>
    </GlobalModal>
  )
}

export default ExportModal