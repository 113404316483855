import React, { useState } from "react";
import ConfirmButton from "../Buttons/ConfirmButton";
import CancelButton from "../Buttons/CancelButton";
import TableCell from "@mui/material/TableCell";
import { Button, Typography } from "@mui/material";
import { WarningModal } from "../Modal";
import api from "../../services/api";
import adminService from "../../services/siteadminService";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
export function ManageAccountsSiteAdmin({ row }) {
  const [showalert, setShowAlert] = useState(false)

  const dispatch = useDispatch()

  const [todelete, setToDelete] = useState()
  const [todeleteEmail, setToDeleteEmail] = useState()
  const deleteHandler = async (id) => {
    try {
      const data = await api.delete(
        `/api/v1/siteadmin/all/delete/${todelete}/${todeleteEmail}`
      )
      if (data && data.ok) {
        toast.success(data.message)
        setShowAlert(true)
        // adminService.getSiteAdmin(dispatch)
        adminService.getSchoolAdmin(dispatch)
        adminService.getSiteAdmin(dispatch)

        adminService.getTeacherAdmin(dispatch)
        adminService.getStudentAdmin(dispatch)
        closeModal()
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    }
  }
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)

  const openModal = (id, email) => {
    setOpen(true)
    setToDelete(id)
    setToDeleteEmail(email)
  }
  const closeModal = () => {
    setOpen(false)
    setToDelete()
    setToDeleteEmail()
    setClose(true)
    // console.log('hello')
  };

  

  const Modal = () => (
    <WarningModal
      open={open}
      close={close}
      // heading='
      // New Account'
    >
      <div className="confirm-close-btns">
        <ConfirmButton>
          <div className='open-btn' onClick={() => deleteHandler(todelete)}>
            Confirm
          </div>
        </ConfirmButton>
        <CancelButton>
          <div className="close-btn" onClick={closeModal}>
            Cancel
          </div>
        </CancelButton>
      </div>
    </WarningModal>
  );

  return (
    <>
      <TableCell align="left">{row.level}</TableCell>
      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.registeredEmail}</TableCell>
      {/* <TableCell align='left'>{row.mean}</TableCell> */}

      <TableCell align="left">
        <Typography>
          <button
            className='remove-btn'
            onClick={() => openModal(row._id, row?.registeredEmail)}
          >
            Remove
          </button>
        </Typography>
        <Modal />
      </TableCell>
    </>
  );
}

export function ManageAccountsSchoolAdmin({ row }) {
  const [open, setOpen] = useState(false)
  const [close, setClose] = useState(false)
  const [todelete, setToDelete] = useState()
  const [todeleteEmail, setToDeleteEmail] = useState()

  const dispatch = useDispatch()
  const [showalert, setShowAlert] = useState(false)
  const openModal = (id, email) => {
    setToDelete(id)
    setToDeleteEmail(email)

    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
    setClose(true)
    setToDelete()
    setToDeleteEmail()
    // console.log('hello')
  };
  const [message, setMessage] = useState();
  const deleteHandler = async (id) => {
    try {
      const data = await api.delete(
        `/api/v1/siteadmin/all/delete/${todelete}/${todeleteEmail}`
      )
      if (data && data.ok) {
        toast.success(data.message);
        setShowAlert(true);
        // adminService.getSiteAdmin(dispatch)
        adminService.getSchoolAdmin(dispatch);
        adminService.getTeacherAdmin(dispatch);
        adminService.getStudentAdmin(dispatch);
        closeModal();
      }
    } catch (error) {
      console.log('error', error)
      toast.error(error.message)
    }
  };
  const Modal = () => (
    <WarningModal
      open={open}
      close={close}
      // heading='
      // New Account'
    >
      <div className="confirm-close-btns">
        <ConfirmButton>
          <div className="open-btn" onClick={() => deleteHandler(todelete)}>
            Confirm
          </div>
        </ConfirmButton>
        <CancelButton>
          <div className="close-btn" onClick={closeModal}>
            Cancel
          </div>
        </CancelButton>
      </div>
    </WarningModal>
  );
  return (
    <>
      <TableCell align="left">{row.level}</TableCell>
      <TableCell align="left">{row.schoolName}</TableCell>

      <TableCell align="left">{row.name}</TableCell>
      <TableCell align="left">{row.registeredEmail}</TableCell>
      {/* <TableCell align='left'>{row.mean}</TableCell> */}

      <TableCell align="left">
        <Typography>
          <button
            className='remove-btn'
            onClick={() => openModal(row._id, row?.registeredEmail)}
          >
            Remove
          </button>
        </Typography>
        <Modal />
      </TableCell>
    </>
  );
}
