import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import config from '../../config'
import Collapse from '@mui/material/Collapse'
import Modal from '@mui/material/Modal'
import api from '../../services/api'
import playIcon from '../../assets/playIcon.svg'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
)
function convertHMS(value) {
  const sec = parseInt(value, 10) // convert value to number if it's string
  let hours = Math.floor(sec / 3600) // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60) // get minutes
  let seconds = sec - hours * 3600 - minutes * 60 //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = hours
  }
  if (minutes < 10) {
    minutes = minutes
  }
  if (seconds < 10) {
    seconds = seconds
  }

  return hours + ':' + minutes + ':' + seconds // Return is HH : MM : SS
}
const options2 = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 3,
    },
  },
  scales: {
    yAxes: {
      display: false,
      position: 'left',
      ticks: {
        display: true,
      },
      title: {
        display: false,
        text: 'Correct Rate',
      },
      grid: {
        display: false,
        zeroLineColor: 'transparent',
      },
    },
    xAxes: {
      display: false,
      ticks: {
        display: false,
      },
      title: {
        display: true,
        text: 'Questions',
      },
      grid: {
        display: false,
        zeroLineColor: 'transparent',
      },
    },
  },

  plugins: {
    legend: {
      position: 'bottom',
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    tooltip: {
      callbacks: {
        title: function (context) {
          let titleIs = 'Views: '

          titleIs += context[0].label
          return titleIs
        },
        label: function (context) {
          let text =
            context.dataset.label +
            ':' +
            convertHMS(parseInt(context.formattedValue))
          return text
        },
      },
    },
  },
}

const CollapsibleTable = ({ qNum, row }) => {
  const [collapase, setCollapse] = useState(false)
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '400px',
    // height: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const [videoAnalyzer, setvideoAnalyzer] = useState({
    labels: [],
    datasets: [],
  })
  const [videoSource, setVideoSource] = useState()
  const playVideo = async (id, source) => {
    setOpen(true)
    setVideoSource(source)
    await api.get(`/api/v1/siteadmin/media/play/${id}`)
  }
  const [videoId, setVideoId] = useState()
  const handleAccordion = async (id) => {
    setCollapse((prev) => !prev)
    setVideoId(id)
  }
  const [message, setMessage] = useState()
  useEffect(() => {
    const getVideoAnalytics = async () => {
      const data = await api.get(`/api/v1/siteadmin/media/${videoId}/average`)
      setMessage()
      if (data && data.ok) {
        let labels = []
        let durations = []

        if (data.data.mostWatched.length) {
          durations = [
            ...data.data.mostWatched.reduce(
              (s, o) => (Object.keys(o).forEach((k) => s.add(k)), s),
              new Set()
            ),
          ]

          for (const obj of data.data?.mostWatched) {
            labels.push(obj[Object.keys(obj)[0]])
          }
        } else {
          setMessage('This video has not been viewed yet.')
        }
        setvideoAnalyzer({
          labels: labels,
          datasets: [
            {
              label: 'Watched Part',
              data: durations,
              borderColor: 'transparent',
              // backgroundColor: gradientValue,
              borderColor: '#EF5B2C',

              fill: true,
              tension: 0.5,
            },
          ],
        })
      }
    }
    collapase && getVideoAnalytics()
  }, [collapase, videoId])

  const buttonCollapse = {
    color: '#EF5B2C',
    border: '0',
    textDecoration: 'underline',
    fontSize: '12px',
    background: 'none',
  }

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <div style={style}>
          <div className='video-content-inner-modal'>
            <video id={row.id} autoPlay controls className='video-content-1'>
              <source src={videoSource} type='video/mp4' />
            </video>
          </div>
        </div>
      </Modal>
      <TableRow>
        <TableCell align='left' sx={{ fontWeight: '600 !important' }}>
          <div className='video-content'>
            <div className='video-content-inner'>
              {row.source ? (
                <video id={row.id} className='video-content-1'>
                  <source
                    src={config.app.apiUrl + '/api/' + row.source}
                    type='video/mp4'
                  />
                </video>
              ) : null}
              {row.source ? (
                <img
                  onClick={() =>
                    playVideo(row.id, config.app.apiUrl + '/api/' + row.source)
                  }
                  src={playIcon}
                  alt='Play'
                />
              ) : null}
            </div>
            {row.source?.split('/').pop() || 'N/a'}
          </div>
        </TableCell>
        <TableCell align='left'>{row && row.length}</TableCell>
        <TableCell align='left'>{row?.rewatchView} </TableCell>
        <TableCell align='left'>
          <button
            style={buttonCollapse}
            onClick={() => handleAccordion(row?.id)}
          >
            {collapase ? 'Collapse' : 'View'}
          </button>
        </TableCell>
      </TableRow>
      {collapase && (
        <TableRow>
          <TableCell colSpan={4}>
            <Collapse in={collapase} timeout='auto' unmountOnExit>
              <div className='video-analyzer'>
                {message ? (
                  <div>{message}</div>
                ) : (
                  <Line
                    options={options2}
                    data={videoAnalyzer && videoAnalyzer}
                    // data={data}
                  />
                )}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const TableVideoAnalytics = ({ data }) => {
  return (
    <div>
      <Table
        aria-label='collapsible table'
        sx={{
          '& .MuiTableCell-head': {
            fontSize: '18px',
            color: 'black',

            fontFamily: 'WorkSans',
            borderTopColor: 'transparent',
            fontWeight: 400,
            letterSpacing: '0.0327273px',
            lineHeight: '22px',
            background: '#F5F5F5',
          },
        }}
      >
        <TableHead>
          <TableRow style={{ fontSize: '0.8em' }}>
            <TableCell align='left'>Video</TableCell>
            <TableCell align='left'>Length</TableCell>
            <TableCell align='left'>ReWatch View</TableCell>
            <TableCell align='left'>Duration History</TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '& .MuiTableCell-body': {
              fontFamily: 'WorkSans',
            },
            '& .MuiTableCell-body:hover': {
              backgroundColor: '#F5F5F5',
            },
          }}
        >
          {data && data.length
            ? data.map((row, index) => {
                return <CollapsibleTable key={row?._id} row={row} />
              })
            : null}
        </TableBody>
      </Table>
    </div>
  )
}

export default TableVideoAnalytics
