import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import TableMain from '../Table'


function inspectCreateData(classname, size, progress, mean) {
  return {
    classname,
    size,
    progress,
    mean,
  }
}



const inspectData = [
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),
  inspectCreateData('3A', 30, 'Week 1:Getting Started', '78% (+2%)'),


]


const inspectHeadCells = [
  {
    id: 'class',
    label: 'Class',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'size',
    label: 'Size',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'progress',
    label: 'Progress',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'mean',
    label: 'Mean scores',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'reassign',
    label: 'Reassign',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'edit',
    label: 'Edit',
    numeric: false,
    disablePadding: false,
  },
]


export default function InspectTeacher() {

  return (
    <div>
      <div>
        <div style={{ float: "left", marginTop: 30, marginLeft: 5, fontSize: 24 }}>
          Mr Prerit Bhandari
        </div>
        <div style={{ float: 'right', marginBottom: 20 }}>
          <button className='school-btn'>Languages / Content Management</button>
          <div style={{ marginBottom: '24.21px' }}>
          </div>
          <button className='school-btn'>Export Report</button>
        </div>
      </div>

      <TableMain
        headCells={inspectHeadCells}
        resultData={inspectData}
        topic='inspteacher'
      />
    </div>
  )
}
