import React, { useState, useEffect } from 'react'
import studentPhoto from '../../assets/studentPhoto.svg'
import uploadIconSm from '../../assets/uploadIconSm.svg'
import exportIcon from '../../assets/exportIcon.svg'
import star from '../../assets/star.svg'
import stars from '../../assets/stars.svg'

import grade from '../../assets/grade.svg'
import cash from '../../assets/cash.svg'
import tick from '../../assets/tick.svg'
import stats from '../../assets/stats.svg'
import api from '../../services/api'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import BreadCrumb from '../../components/BreadCrumb'
import BreadCrumbList from '../../components/BreadCrumbList'
import { GlobalModal } from '../../components/Modal'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Sidebar from '../../components/Sidebar'
import Boxplot, { computeBoxplotStats } from 'react-boxplot'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {
      radius: 3,
    },
  },
  scales: {
    yAxes: {
      display: true,
      ticks: {
        display: true,
      },
      grid: {
        display: true,
        zeroLineColor: 'transparent',
      },
    },
    xAxes: {
      display: true,
      ticks: {
        display: true,
      },
      grid: {
        display: false,
        zeroLineColor: 'transparent',
      },
    },
  },

  plugins: {
    legend: {
      position: 'bottom',
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
}

const Student = ({ match }) => {
  const [studentProfile, setStudentProfile] = useState()
  const classId = localStorage.getItem('classId')
  const [labels, setLabels] = useState([])
  const [rankingHistory, setRankigHistory] = useState([])

  const data = {
    labels,
    datasets: [
      {
        label: 'Dataset 1',
        data: rankingHistory,
        borderColor: '#EF5B2C',
        backgroundColor: '#EF5B2C',
        tension: 0.5,
      },
    ],
  }

  const breadcrumbList = [
    {
      name: 'Dashboard',
      to: '/dashboard',
    },
    {
      name: studentProfile?.schoolId?.name,
      to: `/dashboard/school/${match.params.school}`,
    },
    {
      name: match.params.classroom,
      to: `/dashboard/classroom/${classId}/${match.params.classroom}`,
    },
    {
      name: studentProfile?.name,
      to: `/dashboard/student/${match.params.school}/${match.params.classroom}/${match.params.student}`,
    },
  ]

  const [exportModelState, setExportModelState] = useState(false)
  const exportReportHandler = () => {
    setExportModelState(true)
  }

  const closeExportModel = () => {
    setExportModelState(false)
  }

  // get profile of student
  const getProfile = async () => {
    try {
      const data = await api.get(
        `/api/v1/user/profile/all/${match.params.student}`
      )
      console.log('data is', data)
      if (data && data.ok) {
        setStudentProfile(data.data)
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  useEffect(() => {
    getProfile()
  }, [match.params.student])
  const getGrades = (data) => {
    const total = data.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    )
    return (total / data.length).toFixed(0)
  }

  // NEW API Call author:Subin
  const [totalClassMate, setTotalClassMate] = useState(0)

  const getMyClassMate = async () => {
    try {
      const studentListData = []
      const res = await api.get(
        `/api/v1/student/course/classmates/${studentProfile.classroom._id}`
      )
      if (res && res.ok) {
        setTotalClassMate(res.data.student.length)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (studentProfile) {
      getMyClassMate()
    }
  }, [studentProfile])

  const [allClassroomContent, setAllClassroomContent] = useState()
  const fetchClassRoomContent = async () => {
    try {
      const contentData = []
      const res = await api.get(
        `/api/v1/student/classroomContent/${studentProfile.classroom._id}`
      )
      if (res && res.ok) {
        contentData.push(res.data)
      }
      setAllClassroomContent(contentData[0])
      console.log(contentData[0])
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    if (studentProfile) {
      fetchClassRoomContent()
      fetchQuizReport()
    }
  }, [studentProfile])

  const fetchLabelAndRanking = async () => {
    try {
      if (allClassroomContent && allClassroomContent.length > 0) {
        const data = []
        const rankingData = []
        for (let i = 0; i < allClassroomContent.length; i++) {
          data.push(`w${allClassroomContent[i].week}`)

          const res = await api.post('/api/v1/siteadmin/student/rank', {
            classroomContent: allClassroomContent[i]._id,
            studentId: studentProfile._id,
          })
          if (res && res.ok) {
            rankingData.push(res.yourRanking)
          }
        }
        setLabels(data)
        setRankigHistory(rankingData)
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchLabelAndRanking()
  }, [allClassroomContent])

  console.log(rankingHistory)

  const [currentRanking, setCurrentRanking] = useState(0)

  const getCurrentClassranking = () => {
    for (let i = rankingHistory.length - 1; i >= 0; i--) {
      if (rankingHistory[i] > 0) {
        setCurrentRanking(rankingHistory[i])
        break
      }
    }
  }

  useEffect(() => {
    getCurrentClassranking()
  }, [rankingHistory])

  const [quizReport, setQuizReport] = useState()

  const fetchQuizReport = async () => {
    try {
      const res = await api.post(
        '/api/v1/teacher/classroomContent/quize/getStudentReport',
        {
          studentId: studentProfile._id,
        }
      )
      if (res && res.ok) {
        setQuizReport(res.data)
      }
    } catch (err) {
      console.error(err)
    }
  }
  console.log(quizReport)

  const overallValues = [1, 2, 0, 5, 8, 3, 7, 2, 9, 6]

  // End

  return (
    <>
      <Sidebar />
      <div className='main'>
        <div className='student container-page container-new-page'>
          {studentProfile && <BreadCrumbList values={breadcrumbList} />}
          <div className='top-section'>
            <div>
              <img src={studentPhoto} alt='student photo' />
            </div>
            <div>
              <span>{studentProfile?.name}</span>
              <span>Performance Report</span>
            </div>
            <div>
              <img
                className='uploadIcon'
                src={exportIcon}
                alt='Upload'
                onClick={exportReportHandler}
                style={{ cursor: 'pointer' }}
              />
              <span>Updated By week 5</span>
            </div>
          </div>
          <div className='bottom-section'>
            <div className='bottom-section-left'>
              <div className='heading'>Game Performance</div>

              <div className='stats'>
                <div className='stats-group'>
                  <div className='icon-stats'>
                    <img src={star} alt='star' />
                  </div>
                  <div className='info-stats'>
                    <div className='header'>Current ranking in class</div>
                    <div className='score'>
                      {currentRanking} <span>/{totalClassMate}</span>
                    </div>
                  </div>
                </div>
                <div className='stats-group'>
                  <div className='icon-stats'>
                    <img src={grade} alt='grade' />
                  </div>
                  <div className='info-stats'>
                    <div className='header'>Average Grade</div>
                    <div className='score'>
                      {studentProfile?.studentGrades.length > 0
                        ? getGrades(studentProfile.studentGrades)
                        : 0}{' '}
                      %
                    </div>
                  </div>
                </div>{' '}
                <div className='stats-group'>
                  <div className='icon-stats'>
                    <img src={stars} alt='stars' />
                  </div>
                  <div className='info-stats'>
                    <div className='header'>Company valuation</div>
                    <div className='score'>
                      {studentProfile?.totalCampnayValuation.toFixed(0)}
                    </div>
                  </div>
                </div>{' '}
                <div className='stats-group'>
                  <div className='icon-stats'>
                    <img src={cash} alt='cash' />
                  </div>
                  <div className='info-stats'>
                    <div className='header'>Cash</div>
                    <div className='score'>
                      {studentProfile?.totalCash.toFixed(0)}
                    </div>
                  </div>
                </div>{' '}
                <div className='stats-group'>
                  <div className='icon-stats'>
                    <img src={tick} alt='tick' />
                  </div>
                  <div className='info-stats'>
                    <div className='header'>Company reputation</div>
                    <div className='score'>
                      {studentProfile?.totalcompanyReputation.toFixed(0)}
                    </div>
                  </div>
                </div>{' '}
                <div className='stats-group'>
                  <div className='icon-stats'>
                    <img src={stats} alt='stats' />
                  </div>
                  <div className='info-stats'>
                    <div className='header'>Employee satisfaction</div>
                    <div className='score'>
                      {studentProfile?.totalemploySatisfaction.toFixed(0)}
                    </div>
                  </div>
                </div>
              </div>
              <div className='ranking-history'>
                <div className='heading'>Ranking history</div>
                <Line options={options} data={data} />
              </div>
            </div>
            <div className='bottom-section-right'>
              <div className='bottom-section-right-top'>
                <div className='heading'>Quiz Performance</div>
                <div className='info'>
                  <span>Question Types</span>
                  <span>Correct Rate</span>
                  <span>Avg.</span>
                </div>
                <div className='info-values'>
                  <span>Entrepreneurship</span>
                  <span>something</span>
                  <span>85%</span>
                </div>
                <div className='info-values'>
                  <span>Stem</span>
                  <span>something</span>
                  <span>85%</span>
                </div>
              </div>
              <div className='bottom-section-right-bottom'>
                <div className='header'>
                  <div className='header-first'>
                    <span>Overall Grade </span>{' '}
                    <span>Last update: 15/10/2022</span>{' '}
                  </div>
                  <div>
                    {studentProfile?.studentGrades.length > 0
                      ? getGrades(studentProfile.studentGrades)
                      : 0}
                    %
                  </div>
                  <Boxplot
                    width={200}
                    height={30}
                    orientation='horizontal'
                    min={0}
                    max={10}
                    stats={computeBoxplotStats(overallValues)}
                  />
                </div>
                <div className='border-bottom'></div>
                <div className='data-bottom'>
                  {quizReport && quizReport.length > 0
                    ? quizReport.map((d, index) => {
                        return (
                          <div className='header' key={index}>
                            <div className='header-first'>
                              <span>{d.contentId.title}</span>{' '}
                              <span>Due: {d.createdAt}</span>{' '}
                            </div>
                            <div>{d.gradePercentage}%</div>
                            <Boxplot
                              width={200}
                              height={30}
                              orientation='horizontal'
                              min={0}
                              max={10}
                              stats={computeBoxplotStats(overallValues)}
                            />
                          </div>
                        )
                      })
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GlobalModal
        open={exportModelState}
        close={closeExportModel}
        heading='Export report(s)'
      >
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label='Whole class performance (.pdf)'
          />
          <FormControlLabel
            control={<Checkbox />}
            label='Individual reports (.zip)'
          />
        </FormGroup>

        <div className='btn-groups'>
          <button className='export'>Confirm</button>
          <button className='cancel' onClick={closeExportModel}>
            Cancel
          </button>
        </div>
      </GlobalModal>
    </>
  )
}

export default Student
