import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SetProps, TabPanel } from "../../Tabs";
import GameCard from "./GameCard";
import { usePlatform } from "../../../context/PlatformManagement";

export default function Game({ content1, content2, content3 }) {
  const { gameTabValue, setGameTabValue } = usePlatform();

  const handleChange = (event, newValue) => {
    setGameTabValue(newValue);
  };
  return (
    <Box sx={{ width: "100%", paddingTop: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          sx={{
            "& .MuiButtonBase-root": {
              width: 290,
            },
            "& .MuiTabs-indicator": {
              borderBottomColor: "#EF5B2C !important",
            },
          }}
          value={gameTabValue}
          onChange={handleChange}
        >
          <Tab label="4 MODULE" {...SetProps(0)} />
          <Tab label="8 MODULE" {...SetProps(1)} />
          <Tab label="12 MODULE" {...SetProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={gameTabValue} index={0}>
        <GameCard noOfWeek={4} gameData={content1} />
      </TabPanel>
      <TabPanel value={gameTabValue} index={1}>
        <GameCard noOfWeek={8} gameData={content2} />
      </TabPanel>
      <TabPanel value={gameTabValue} index={2}>
        <GameCard noOfWeek={12} gameData={content3} />
      </TabPanel>
    </Box>
  );
}
