import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import pdfIcon from "../../assets/pdfIcon.svg";
import Modal from "@mui/material/Modal";

export default function AnalyticsMediaText({ row }) {
  //   let videos = []
  const [open, setOpen] = useState(false);
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width: '400px',
    // height: '400px',
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const [videoSource, setVideoSource] = useState();
  const playVideo = (id, source) => {
    setOpen(true);
    setVideoSource(source);
  };
  const playVideoOnModal = (id) => {
    // let videos = [...document.getElementsByClassName('video-content-1')]
    // let myVideo = document.getElementById(id)
    // videos.forEach((video) => {
    //   video.id === id && video.pause()
    // })
    // if (myVideo.paused) {
    //   myVideo.play()
    //   console.log('play')
    // } else {
    //   myVideo.pause()
    //   console.log('pause')
    // }
  };
  return (
    <>
      <TableCell align='left' sx={{ fontWeight: "600 !important" }}>
        <div className='video-content'>
          <div
            className='video-content-inner'
            style={{ width: "100px", height: "80px" }}
          >
            <img
              src={pdfIcon}
              alt='pdfIcon'
              style={{
                width: "70%",
                objectFit: "contain",
                height: "70%",
              }}
            />
          </div>
          {row.name}
        </div>
      </TableCell>
      <TableCell align='left'>{row.words}</TableCell>
      <TableCell align='left'>{Math.round(row.avgReadDuration) || Math.ceil(row.words / 300) + " m" || "0"  }</TableCell>
    </>
  );
}
