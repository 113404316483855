import config from "../config";

export function isServer() {
  return typeof window === "undefined";
}

export const consoleLog = (data) => {
  if (process.env.NODE_ENV === "development") {
    console.log(data);
  }
};

export const consoleError = (data) => {
  if (process.env.NODE_ENV === "development") {
    console.error(data);
  }
};

export const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export function avatarUrl(url) {
  if (url) {
    if (url.includes("https://") || url.includes("http://")) return url;
    return `${config.app.uploadUrl}${url}`;
  }
  return `/avatar.png`;
}

export function getCookie(name) {
  if (process.browser) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}

export function debounceFunction(func, delay) {
  let timer;
  return function (...args) {
    let context = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
}

export function getOnlyFolderStructure(link) {
  const onlyFolderLink = link.split(
    "https://stemunicorn-dev-buck.s3.amazonaws.com/"
  )[1];
  return onlyFolderLink;
}
