import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SetProps, TabPanel } from "../../Tabs";
import EditContentByWeek from "./EditContainByWeek";
import { usePlatform } from "../../../context/PlatformManagement";

const EditContent = ({ val, setVal, content }) => {
  // const { lessonMgmtTabValue, setLessonMgmtValue } = usePlatform();
  const [value, setValue] = React.useState(0);
  // console.log(lessonMgmtTabValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className='editContentWrapper'>
      <Box sx={{ width: "100%", paddingTop: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          {/* <Tabs
            sx={{
              "& .MuiButtonBase-root": {
                width: 290,
              },
              "& .MuiTabs-indicator": {
                borderBottomColor: "#EF5B2C !important",
              },
            }}
            value={value}
            onChange={handleChange}
          >
            <Tab label="4 WEEKS" {...SetProps(0)} />
            <Tab label="8 WEEKS" {...SetProps(1)} />
            <Tab label="12 WEEKS" {...SetProps(2)} />
          </Tabs> */}
        </Box>
        <TabPanel value={value} index={0}>
          <EditContentByWeek
            val={val}
            setVal={setVal}
            noOfWeek={4}
            content={content}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditContentByWeek
            val={val}
            setVal={setVal}
            noOfWeek={8}
            content={content}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <EditContentByWeek
            val={val}
            setVal={setVal}
            noOfWeek={12}
            content={content}
          />
        </TabPanel>
      </Box>
    </div>
  );
};
export default EditContent;
