import React, { useState, useEffect } from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { TabPanel } from '../../components/Tabs'
import Box from '@mui/material/Box'
import { SetProps } from '../../components/Tabs'
import TableMain from '../Table'
import { WarningModal, GlobalModal } from '../Modal'
import ConfirmButton from '../Buttons/ConfirmButton'
import CancelButton from '../Buttons/CancelButton'
import { useDispatch, useSelector } from 'react-redux'
import adminService from '../../services/siteadminService'
import config from '../../config'
import { jsPDF } from 'jspdf'
import TableVideoAnalytics from './TableVideoAnalytics'
const AnalyticsMedia = () => {
  const dispatch = useDispatch()
  const media = useSelector((state) => state.siteadmin.media)
  const mediaText = useSelector((state) => state.siteadmin.mediaText)

  const [value, setValue] = React.useState(0)
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [data, setData] = useState([])
  const [exportErr, setExportErr] = useState('')
  const [exportPDF, setExportEntire] = useState(false)
  const [exportPDFSingle, setExportPerPage] = useState(false)

  useEffect(() => {
    adminService.getMedia(dispatch)
    adminService.getMediaText(dispatch)
  }, [])
  useEffect(() => {
    if (!media) return
    const list = media.map((item) => {
      return {
        id: item._id,
        title: item?.videoTitle,
        source: item.url,
        length: item.videoLength || 'N/a',
        rewatchView: item.rewatchView,
      }
    })
    setData(list)
  }, [media])
  const handleExportVideo = () => {
    let pdf = exportPDF
    let zip = exportPDFSingle
    if (!pdf && !zip) {
      setExportErr('Click on checkbox to download')
      const errorHandle = setTimeout(() => {
        setExportErr('')
        clearInterval(errorHandle)
      }, 4000)
    }
    if (pdf) {
      setExportErr('')
      ConvertVideoToPDF(0, data, 'VideoSetPDFAll.pdf')
      ConvertTextToPDF(0, mediaText, 'TextSetPDFAll.pdf')
    }
    if (zip) {
      setExportErr('')
      ConvertVideoToPDF(10, data, 'VideoSetPDFSingle.pdf')
      ConvertTextToPDF(10, mediaText, 'TextSetPDFSingle.pdf')
    }
  }
  function ConvertVideoToPDF(num, file, name) {
    const result = (total) => {
      const fileSet = total ? file.splice(0, total) : file
      if (fileSet && fileSet.length) {
        fileSet.map((item, index) => {
          Object.entries(item).map(([key, value]) => {
            item.id = (index + 1).toString()
            item[key] = value ? value?.toString() : 'N/A'
          })
        })
      }
      return fileSet
    }
    function createHeaders(keys) {
      var result = []
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i],
          name: keys[i],
          prompt: keys[i],
          width: 65,
          align: 'center',
          padding: 0,
        })
      }
      return result
    }

    const headers = createHeaders([
      'id',
      'title',
      'source',
      'length',
      'rewatchView',
    ])
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' })
    doc.table(1, 1, result(num), headers, { autoSize: true })
    doc.save(name)
  }
  function ConvertTextToPDF(num, file, name) {
    const result = (total) => {
      const fileSet = total ? file.splice(0, total) : file
      if (fileSet && fileSet.length) {
        fileSet.map((item, index) => {
          Object.entries(item).map(([key, value]) => {
            item.id = (index + 1).toString()
            item[key] = value ? value?.toString() : 'N/A'
          })
        })
      }
      return fileSet
    }
    function createHeaders(keys) {
      var result = []
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i],
          name: keys[i],
          prompt: keys[i],
          width: 65,
          align: 'center',
          padding: 0,
        })
      }
      return result
    }

    const headers = createHeaders(['id', 'name', 'words', 'avgReadDuration'])
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: 'landscape' })
    doc.table(1, 1, result(num), headers, { autoSize: true })
    doc.save(name)
  }
  //   table cells
  const headCells = [
    {
      id: 'videoTitle',
      label: 'Video Title',
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'length',
      label: 'Length',
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'rewatchView',
      label: 'Re-watch View',
      numeric: false,
      disablePadding: false,
    },
    {
      id: '',
      label: 'Duration History',
      numeric: false,
      disablePadding: false,
    },
  ]

  // for media text
  const headCells1 = [
    {
      id: 'textTitle',
      label: 'Text Title',
      numeric: false,
      disablePadding: false,
    },

    {
      id: 'words',
      label: 'Words',
      numeric: false,
      disablePadding: false,
    },
    {
      id: 'averageReadingDuration',
      label: 'Avg Reading Duration',
      numeric: false,
      disablePadding: false,
    },
  ]
  return (
    <div className='analyticsMediaContainer'>
      <GlobalModal open={open} close={handleClose} heading='Export Report(s)'>
        <div className='middle-content-export'>
          <div className='group'>
            <input
              onChange={(e) => {
                setExportEntire(e.target.checked)
              }}
              type='checkbox'
              name=''
              id='entireDocument'
            />
            <label htmlFor='entireDocument'>
              Whole class performance (.pdf)
            </label>
          </div>
          <div className='group'>
            <input
              onChange={(e) => {
                setExportPerPage(e.target.checked)
              }}
              type='checkbox'
              name=''
              id='basedOnPageZip'
            />
            <label htmlFor='basedOnPageZip'>Individual reports (.zip)</label>
          </div>
        </div>
        <br />
        {exportErr ? (
          <div className='text-center'>
            <strong className='text-danger'>{exportErr}</strong>
          </div>
        ) : null}
        <br />
        <div className='confirm-close-btns'>
          <ConfirmButton>
            <div onClick={handleExportVideo} className='open-btn'>
              Confirm
            </div>
          </ConfirmButton>
          <CancelButton>
            <div className='close-btn' onClick={handleClose}>
              Cancel
            </div>
          </CancelButton>
        </div>
      </GlobalModal>
      <div className='' style={{ width: '100%' }}>
        <div className='tabs-list'>
          <Tabs
            sx={{ width: '100%', marginBottom: '330px' }}
            value={value}
            indicatorColor='secondary'
            onChange={handleChange}
            sx={{ textTransform: 'lowercase' }}
          >
            <Tab label='Video' {...SetProps(0)} />
            <Tab label='Text' {...SetProps(1)} />
          </Tabs>
          {/* <button className='btn-analytics' onClick={handleOpen}>
            Export
          </button> */}
        </div>
        <TabPanel value={value} index={0}>
          <div className='analyticsSystem-outer'>
            <Box sx={{ width: '100%' }}>
              {data && data.length ? (
                <TableVideoAnalytics data={data} />
              ) : // <TableMain
              //   headCells={headCells}
              //   resultData={data}
              //   topic='AnalyticsMedia'
              // />
              null}
            </Box>
          </div>
        </TabPanel>{' '}
        <TabPanel value={value} index={1}>
          <div className='analyticsSystem-outer'>
            {mediaText && mediaText.length ? (
              <Box sx={{ width: '100%' }}>
                <TableMain
                  headCells={headCells1}
                  resultData={mediaText}
                  topic='AnalyticsMediaText'
                />
              </Box>
            ) : null}
          </div>
        </TabPanel>{' '}
      </div>
      {/* <button className='btn-analytics'>Export</button> */}
    </div>
  )
}

export default AnalyticsMedia
