import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../components/Tabs";
import { SetProps } from "../../components/Tabs";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import adminService from "../../services/siteadminService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableMain from "../../components/Table";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import OptionGroupUnstyled from "@mui/base/OptionGroupUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import ConfirmButton from "../../components/Buttons/ConfirmButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { GlobalModal } from "../../components/Modal";
import Sidebar from "../../components/Sidebar";
import { consoleError } from "../../utils/helper";
import api from "../../services/api";
import { toast } from "react-toastify";
import siteadminService from "../../services/siteadminService";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: WorkSans;
  font-size: 0.875rem;
  box-sizing: border-box;
  width:250px;
 

  min-width: ${theme.breakpoints.up("md") ? "283px" : "220px"};;
  
  
  background:white;
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 2px;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: WorkSans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  
 
  
  min-width: ${theme.breakpoints.up("md") ? "283px" : "220px"};;

  
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledGroupRoot = styled("li")`
  list-style: none;
`;

const StyledGroupHeader = styled("span")`
  display: block;
  padding: 15px 0 5px 10px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${grey[600]};
`;

const StyledGroupOptions = styled("ul")`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding-left: 20px;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function CustomSelect(props) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};

const CustomOptionGroup = React.forwardRef(function CustomOptionGroup(
  props,
  ref
) {
  const components = {
    Root: StyledGroupRoot,
    Label: StyledGroupHeader,
    List: StyledGroupOptions,
    ...props.components,
  };

  return <OptionGroupUnstyled {...props} ref={ref} components={components} />;
});

CustomOptionGroup.propTypes = {
  /**
   * The components used for each slot inside the OptionGroupUnstyled.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Label: PropTypes.elementType,
    List: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
};
const ManageAccounts = () => {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const dispatch = useDispatch();

  const siteAdmin = useSelector((state) => state.siteadmin.site_admin);
  const siteSchoolAdmin = useSelector((state) => state.siteadmin.school_admin);
  const teacherAdmin = useSelector((state) => state.siteadmin.teacher_admin);
  const studentAdmin = useSelector((state) => state.siteadmin.student_admin);
  const allSchools = useSelector((state) => state.siteadmin.schools);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setClose(true);
    setSchoolUserFormState({
      name: "",
      email: "",
      password: "",
      role: "",
      schoolId: "",
    });
    setSiteAdminFormState({
      name: "",
      email: "",
      password: "",
      role: "siteadmin",
      subrole: "",
    });
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [headCells, setHeadCells] = React.useState([
    {
      // id: 'level',/
      label: "level",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "name",
      label: "name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "registeredEmail",
      label: "Registered Email",
      numeric: false,
      disablePadding: false,
    },
    {
      // id: 'Edit',
      label: "Edit",
      numeric: false,
      disablePadding: false,
    },
  ]);
  const [headCells1, setHeadCells1] = React.useState([
    {
      id: "level",
      label: "level",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "level",
      label: "schoolName",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "name",
      label: "name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "registeredEmail",
      label: "Registered Email",
      numeric: false,
      disablePadding: false,
    },
    {
      // id: 'Edit',
      label: "Edit",
      numeric: false,
      disablePadding: false,
    },
  ]);
  function UnstyledSelectGrouping() {
    // -Country-
    // Denmark
    // Japan
    // Hong Kong SAR
    // Korea
    // Mainland China
    return (
      <CustomSelect sx={{ marginLeft: "-5px" }}>
        <CustomOptionGroup label="-Region-">
          <StyledOption value="Frodo">Asia Pacific</StyledOption>
          <StyledOption value="Sam"> Europe</StyledOption>
          <StyledOption value="Merry"> Americas</StyledOption>
        </CustomOptionGroup>
        <CustomOptionGroup label="-Country-">
          <StyledOption value="Galadriel">Denmark</StyledOption>
          <StyledOption value="Japan">Japan</StyledOption>
          <StyledOption value="Hong Kong SAR">Hong Kong SAR</StyledOption>
          <StyledOption value="Korea">Korea</StyledOption>
          <StyledOption value="Mainland China">Mainland China</StyledOption>
        </CustomOptionGroup>
      </CustomSelect>
    );
  }
  const [resultData, setResultData] = useState([]);
  const [resultData1, setResultData1] = useState([]);
  const [resultData2, setResultData2] = useState([]);
  const [resultData3, setResultData3] = useState([]);

  const addSiteadmin = () => {
    // console.log(account)
  };

  useEffect(() => {
    adminService.getSiteAdmin(dispatch);
    adminService.getSchoolAdmin(dispatch);
    adminService.getTeacherAdmin(dispatch);
    adminService.getStudentAdmin(dispatch);
    siteadminService.getSchools(dispatch);
  }, []);

  useEffect(() => {
    if (siteAdmin && siteAdmin.length) {
      const res1 = siteAdmin.map((sadmin) => {
        return {
          _id: sadmin._id,
          level: sadmin?.role,
          name: sadmin?.name,
          registeredEmail: sadmin?.email,
        };
      });
      setResultData(res1);
    }

    if (siteSchoolAdmin && siteSchoolAdmin.length) {
      const res2 = siteSchoolAdmin.map((school) => {
        return {
          _id: school._id,
          level: school?.role,
          schoolName: school?.schoolId?.name,
          name: school?.name,
          registeredEmail: school?.email,
        };
      });
      setResultData1(res2);
    }

    if (teacherAdmin && teacherAdmin.length) {
      const res3 = teacherAdmin.map((teacher) => {
        return {
          _id: teacher._id,
          level: teacher?.role,
          schoolName: teacher?.schoolId?.name,
          name: teacher?.name,
          registeredEmail: teacher?.email,
        };
      });
      setResultData2(res3);
    }

    if (studentAdmin && studentAdmin.length) {
      const res4 = studentAdmin.map((student) => {
        return {
          _id: student._id,
          level: student?.role,
          schoolName: student?.schoolId?.name,
          name: student?.name,
          registeredEmail: student?.email,
        };
      });
      setResultData3(res4);
    }
  }, [siteAdmin, siteSchoolAdmin, teacherAdmin, studentAdmin]);

  // STATE OF ADD NEW SITEADMIN STARTS
  const [siteAdminFormState, setSiteAdminFormState] = useState({
    name: "",
    email: "",
    // password: "",
    role: "siteadmin",
    subrole: "",
    status: "unverified",
  });

  const siteAdminInputChangeHandler = (e) => {
    const { name, value } = e.target;

    setSiteAdminFormState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const siteAdminRegisterHandler = async () => {
    try {
      let values = [];
      for (var key in siteAdminFormState) {
        values.push(siteAdminFormState[key]);
      }
      let filtered = values.filter((value) => value !== "");
      console.log("values", filtered);
      if (filtered.length < 4) {
        toast.error("Please fill all the input fields");
        return;
      }

      const res = await api.post(
        "/api/v1/siteadmin/add/new/user",
        siteAdminFormState
      );
      if (res && res.ok) {
        closeModal();
        adminService.getSiteAdmin(dispatch);
        setSiteAdminFormState({
          name: "",
          email: "",
          password: "",
          role: "siteadmin",
          subrole: "",
          status: "unverified",
        });
      }
    } catch (err) {
      consoleError(err);
      toast.error(err.message);
    }
  };
  // STATE OF ADD NEW SITEADMIN ENDS

  // STATE OF ADD NEW SCHOOL USER STARTS
  const [schoolUserFormState, setSchoolUserFormState] = useState({
    name: "",
    email: "",
    // password: "",
    role: "",
    schoolId: "",
    status: "unverified",
  });

  const schoolUserInputChangeHandler = (e) => {
    const { name, value } = e.target;

    setSchoolUserFormState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const addSchoolUserHandler = async (role1) => {
    console.log("role is", role1);
    // console.log(schoolUserFormState);
    try {
      let values = [];
      let newList = { ...schoolUserFormState, role: role1 };
      // setSchoolUserFormState({ ...newList, role: role1 })
      for (var key in newList) {
        values.push(newList[key]);
      }
      let filtered = values.filter((value) => value !== "");
      console.log("values", filtered);
      if (filtered.length < 4) {
        toast.error("Please fill all the input fields");
        return;
      }
      const res = await api.post("/api/v1/siteadmin/add/new/user", newList);
      if (res && res.ok) {
        closeModal();
        adminService.getSchoolAdmin(dispatch);
        adminService.getTeacherAdmin(dispatch);
        adminService.getStudentAdmin(dispatch);
        toast.success(res.message);

        setSchoolUserFormState({
          name: "",
          email: "",
          password: "",
          role: "",
          schoolId: "",
        });
      }
    } catch (err) {
      consoleError(err);
      toast.error(err.message);
    }
  };
  // STATE OF ADD NEW SCOOL USER ENDS
  const [expiryDateSchoolAdmin, setExpiryDateSchoolAdmin] = useState(false);

  const [ascendingSchooladmin, setAscendingSchoolAdmin] = useState(false);
  const [descendingSchooladmin, setDescendingSchoolAdmin] = useState(false);
  const checkBoxHandler = async (e, type) => {
    console.log("type", type);
    switch (type) {
      case "ascendingSchooladmin":
        setAscendingSchoolAdmin(true);
        setExpiryDateSchoolAdmin(false);
        setDescendingSchoolAdmin(false);

        const data = await api.get(
          `/api/v1/siteadmin/filter/schooladmin/all?accSchool=1`
        );
        if (data && data.ok) {
          const res2 = data.accDate.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData1(res2);
        }

        break;

      case "descendingSchooladmin":
        setDescendingSchoolAdmin(true);
        setExpiryDateSchoolAdmin(false);
        setAscendingSchoolAdmin(false);
        const data1 = await api.get(
          `/api/v1/siteadmin/filter/schooladmin/all?descSchool=1`
        );
        if (data1 && data1.ok) {
          const res2 = data1.decDate.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData1(res2);
        }

        break;

      case "expiryDateSchoolAdmin":
        setExpiryDateSchoolAdmin(true);
        setAscendingSchoolAdmin(false);
        setDescendingSchoolAdmin(false);
        const data2 = await api.get(
          `/api/v1/siteadmin/filter/schooladmin/all?expiryDate=1`
        );
        if (data2 && data2.ok) {
          const res2 = data2.expiryData.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData1(res2);
        }
        break;
    }
  };

  // for teacher
  const [expiryDateTeacherAdmin, setExpiryDateTeacherAdmin] = useState(false);

  const [ascendingTeacheradmin, setAscendingTeacherAdmin] = useState(false);
  const [descendingTeacheradmin, setDescendingTeacherAdmin] = useState(false);
  const checkBoxHandler1 = async (e, type) => {
    console.log("type", type);
    switch (type) {
      case "ascendingTeacheradmin":
        setAscendingTeacherAdmin(true);
        setExpiryDateTeacherAdmin(false);
        setDescendingTeacherAdmin(false);

        const data = await api.get(
          `/api/v1/siteadmin/filter/teacheradmin/date-name?accSchool=1`
        );
        if (data && data.ok) {
          const res2 = data.accDate.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData2(res2);
        }

        break;

      case "descendingTeacheradmin":
        setDescendingTeacherAdmin(true);
        setExpiryDateTeacherAdmin(false);
        setAscendingTeacherAdmin(false);
        const data1 = await api.get(
          `/api/v1/siteadmin/filter/teacheradmin/date-name?descSchool=1`
        );
        if (data1 && data1.ok) {
          const res2 = data1.decDate.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData2(res2);
        }

        break;

      case "expiryDateTeacherAdmin":
        setExpiryDateTeacherAdmin(true);
        setAscendingTeacherAdmin(false);
        setDescendingTeacherAdmin(false);
        const data2 = await api.get(
          `/api/v1/siteadmin/filter/teacheradmin/date-name?expiryDate=1`
        );
        if (data2 && data2.ok) {
          const res2 = data2.expiryData.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData2(res2);
        }
        break;
    }
  };

  // for student
  // for teacher
  const [expiryDateStudentAdmin, setExpiryDateStudentAdmin] = useState(false);

  const [ascendingStudentadmin, setAscendingStudentAdmin] = useState(false);
  const [descendingStudentadmin, setDescendingStudentAdmin] = useState(false);
  const checkBoxHandler2 = async (e, type) => {
    console.log("type", type);
    switch (type) {
      case "ascendingStudentadmin":
        setAscendingStudentAdmin(true);
        setExpiryDateStudentAdmin(false);
        setDescendingStudentAdmin(false);

        const data = await api.get(
          `/api/v1/siteadmin/student-name-expirydate?accSchool=1`
        );
        if (data && data.ok) {
          const res2 = data.accDate.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData3(res2);
        }

        break;

      case "descendingStudentadmin":
        setDescendingStudentAdmin(true);
        setExpiryDateStudentAdmin(false);
        setAscendingStudentAdmin(false);
        const data1 = await api.get(
          `/api/v1/siteadmin/student-name-expirydate?descSchool=1`
        );
        if (data1 && data1.ok) {
          const res2 = data1.decDate.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData3(res2);
        }

        break;

      case "expiryDateStudentAdmin":
        setExpiryDateStudentAdmin(true);
        setAscendingStudentAdmin(false);
        setDescendingStudentAdmin(false);
        const data2 = await api.get(
          `/api/v1/siteadmin/student-name-expirydate?expiryDate=1`
        );
        if (data2 && data2.ok) {
          const res2 = data2.expiryData.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData3(res2);
        }
        break;
    }
  };

  // sieadmin filter
  const [owner, setOwner] = useState(false);

  const [viewer, setViewer] = useState(false);
  const [editor, setEditor] = useState(false);
  const [allEntries, setAllEntries] = useState(false);

  const checkBoxHandler0 = async (e, type) => {
    console.log("type", type);
    switch (type) {
      case "viewer":
        setViewer(true);
        setOwner(false);
        setEditor(false);
        setAllEntries(false);

        const data = await api.get(`/api/v1/siteadmin/filter/${type}`);
        if (data && data.ok) {
          const res2 = data.data.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData(res2);
        }

        break;

      case "editor":
        setEditor(true);
        setOwner(false);
        setAllEntries(false);

        setViewer(false);
        const data1 = await api.get(`/api/v1/siteadmin/filter/${type}`);
        if (data1 && data1.ok) {
          const res2 = data1.data.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData(res2);
        }

        break;

      case "owner":
        setOwner(true);
        setViewer(false);
        setEditor(false);
        setAllEntries(false);
        const data2 = await api.get(`/api/v1/siteadmin/filter/${type}`);
        if (data2 && data2.ok) {
          const res2 = data2.data.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData(res2);
        }
        break;
      case "allEntries":
        setOwner(false);
        setViewer(false);
        setEditor(false);
        setAllEntries(true);
        // let type=""
        const data3 = await api.get(`/api/v1/siteadmin/all/siteadmin`);
        if (data3 && data3.ok) {
          const res2 = data3.data.map((school) => {
            return {
              _id: school._id,
              level: school?.role,
              schoolName: school?.schoolId?.name,
              name: school?.name,
              registeredEmail: school?.email,
            };
          });
          setResultData(res2);
        }
        break;
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="heading-main">Manage Accounts</div>

        <div className="manage-accounts container-page">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <div className="tabs-section">
                <Tabs
                  sx={{ width: "80%", marginBottom: "33px" }}
                  value={value}
                  indicatorColor="secondary"
                  onChange={handleChange}
                  sx={{ textTransform: "lowercase" }}
                >
                  <Tab label="Site Admin" {...SetProps(0)} />
                  <Tab label="Sch Admin" {...SetProps(1)} />
                  <Tab label="Teacher" {...SetProps(2)} />
                  <Tab label="Student" {...SetProps(3)} />
                </Tabs>
              </div>
            </Box>

            <TabPanel value={value} index={0} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
      New Account Site Admin"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={siteAdminFormState.name}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={siteAdminFormState.email}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={siteAdminFormState.password}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">Sub Role</label>
                      <select
                        name="subrole"
                        value={siteAdminFormState.subrole}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      >
                        <option>Select role</option>
                        <option value="owner">Owner</option>
                        <option value="editor">Editor</option>
                        <option value="viewer">Viewer</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={siteAdminRegisterHandler}
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn " onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCells}
                    resultData={resultData}
                    topic="manage-accounts-siteAdmin"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">Sorting</span>
                    <div className="data">
                      <input
                        value={allEntries}
                        onChange={(e) => checkBoxHandler0(e, "allEntries")}
                        checked={allEntries}
                        type="checkbox"
                        name="allEntries"
                        id=""
                      />{" "}
                      <span>All Entries</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">Access Level</span>
                    <div className="access-levels-inner">
                      <div className="data">
                        <input
                          value={owner}
                          onChange={(e) => checkBoxHandler0(e, "owner")}
                          checked={owner}
                          type="checkbox"
                          name="owner"
                          id=""
                        />{" "}
                        <span>Owner</span>
                      </div>
                      <div className="data">
                        <input
                          value={viewer}
                          onChange={(e) => checkBoxHandler0(e, "viewer")}
                          checked={viewer}
                          type="checkbox"
                          name="viewer"
                          id=""
                        />{" "}
                        <span>Viewer</span>
                      </div>{" "}
                      <div className="data">
                        <input
                          value={editor}
                          onChange={(e) => checkBoxHandler0(e, "editor")}
                          checked={editor}
                          type="checkbox"
                          name="editor"
                          id=""
                        />{" "}
                        <span>Editor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
      Add school admin"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={schoolUserFormState.name}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={schoolUserFormState.email}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={schoolUserFormState.password}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="email">School</label>
                      <select
                        name="schoolId"
                        value={schoolUserFormState.schoolId}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        <option>Select School</option>
                        {allSchools && allSchools.length > 0
                          ? allSchools.map((school, index) => {
                              return (
                                <option key={index} value={school._id}>
                                  {school.name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="accountType">Account Type</label>
                      <select
                        name="role"
                        value={schoolUserFormState.role}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        {/* <option>Select Role</option> */}
                        <option value="school">School Admin</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={(role) =>
                          addSchoolUserHandler((role = "school"))
                        }
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn" onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCells1}
                    resultData={resultData1}
                    topic="manage-accounts-schoolAdmin"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">License</span>
                    <div className="data">
                      <input
                        value={expiryDateSchoolAdmin}
                        onChange={(e) =>
                          checkBoxHandler(e, "expiryDateSchoolAdmin")
                        }
                        type="checkbox"
                        checked={expiryDateSchoolAdmin}
                        name="expiryDateSchoolAdmin"
                        id=""
                      />
                      <span>List By Expiry Date</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">School</span>
                    <div className="access-levels-inner">
                      <div className="access-level-inner_left">
                        <div className="data">
                          <input
                            value={ascendingSchooladmin}
                            onChange={(e) =>
                              checkBoxHandler(e, "ascendingSchooladmin")
                            }
                            checked={ascendingSchooladmin}
                            type="checkbox"
                            name="ascendingSchooladmin"
                            id=""
                          />
                          <span>A - Z</span>
                        </div>
                        <div className="data">
                          <input
                            value={descendingSchooladmin}
                            onChange={(e) =>
                              checkBoxHandler(e, "descendingSchooladmin")
                            }
                            type="checkbox"
                            name="descendingSchooladmin"
                            id=""
                            checked={descendingSchooladmin}
                          />

                          <span>Z - A</span>
                        </div>{" "}
                      </div>
                      {/* <div className='access-level-inner_right'>
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>4-week</span>
                        </div>{' '}
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>8-week</span>
                        </div>{' '}
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>12-week</span>
                        </div>{' '}
                      </div> */}
                    </div>
                  </div>
                  <div className="sorting">
                    <br />
                    <span className="header">Region / Country</span>
                    <div className="data">
                      <Box>
                        <UnstyledSelectGrouping />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* Teacher */}
            <TabPanel value={value} index={2} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
    New Teacher"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={schoolUserFormState.name}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={schoolUserFormState.email}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={schoolUserFormState.password}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="email">School</label>
                      <select
                        name="schoolId"
                        value={schoolUserFormState.schoolId}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        <option>Select School</option>
                        {allSchools && allSchools.length > 0
                          ? allSchools.map((school, index) => {
                              return (
                                <option key={index} value={school._id}>
                                  {school.name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="accountType">Account Type</label>
                      <select
                        name="role"
                        value={schoolUserFormState.role}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        {/* <option>Select Role</option> */}
                        <option value="teacher">Teacher</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={(role) =>
                          addSchoolUserHandler((role = "teacher"))
                        }
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn" onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCells1}
                    resultData={resultData2}
                    topic="manage-accounts-schoolAdmin"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">License</span>
                    <div className="data">
                      <input
                        value={expiryDateTeacherAdmin}
                        onChange={(e) =>
                          checkBoxHandler1(e, "expiryDateTeacherAdmin")
                        }
                        type="checkbox"
                        checked={expiryDateTeacherAdmin}
                        name="expiryDateTeacherAdmin"
                        id=""
                      />
                      <span>List By Expiry Date</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">School</span>
                    <div className="access-levels-inner">
                      <div className="access-level-inner_left">
                        <div className="data">
                          <input
                            value={ascendingTeacheradmin}
                            onChange={(e) =>
                              checkBoxHandler1(e, "ascendingTeacheradmin")
                            }
                            checked={ascendingTeacheradmin}
                            type="checkbox"
                            name="ascendingTeacheradmin"
                            id=""
                          />{" "}
                          <span>A - Z</span>
                        </div>
                        <div className="data">
                          <input
                            value={descendingTeacheradmin}
                            onChange={(e) =>
                              checkBoxHandler1(e, "descendingTeacheradmin")
                            }
                            type="checkbox"
                            name="descendingTeacheradmin"
                            id=""
                            checked={descendingTeacheradmin}
                          />
                          <span>Z - A</span>
                        </div>{" "}
                      </div>
                      {/* <div className='access-level-inner_right'>
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>4-week</span>
                        </div>{' '}
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>8-week</span>
                        </div>{' '}
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>12-week</span>
                        </div>{' '}
                      </div> */}
                    </div>
                  </div>
                  <div className="sorting">
                    <br />
                    <span className="header">Region / Country</span>
                    <div className="data">
                      <Box>
                        <UnstyledSelectGrouping />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* Teacher ends */}

            {/* Student */}
            <TabPanel value={value} index={3} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
    Add student"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={schoolUserFormState.name}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={schoolUserFormState.email}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    {/* <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={schoolUserFormState.password}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div> */}
                    <div className="form-group">
                      <label htmlFor="email">School</label>
                      <select
                        name="schoolId"
                        value={schoolUserFormState.schoolId}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        <option>Select School</option>
                        {allSchools && allSchools.length > 0
                          ? allSchools.map((school, index) => {
                              return (
                                <option key={index} value={school._id}>
                                  {school.name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="accountType">Account Type</label>
                      <select
                        name="role"
                        value={schoolUserFormState.role}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        {/* <option>Select Role</option> */}
                        <option value="student">Student</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={(role) =>
                          addSchoolUserHandler((role = "student"))
                        }
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn" onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCells1}
                    resultData={resultData3}
                    topic="manage-accounts-schoolAdmin"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">License</span>
                    <div className="data">
                      <input
                        value={expiryDateStudentAdmin}
                        onChange={(e) =>
                          checkBoxHandler2(e, "expiryDateStudentAdmin")
                        }
                        type="checkbox"
                        checked={expiryDateStudentAdmin}
                        name="expiryDateStudentAdmin"
                        id=""
                      />
                      <span>List By Expiry Date</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">School</span>
                    <div className="access-levels-inner">
                      <div className="access-level-inner_left">
                        <div className="data">
                          <input
                            value={ascendingStudentadmin}
                            onChange={(e) =>
                              checkBoxHandler2(e, "ascendingStudentadmin")
                            }
                            checked={ascendingStudentadmin}
                            type="checkbox"
                            name="ascendingStudentadmin"
                            id=""
                          />{" "}
                          <span>A - Z</span>
                        </div>
                        <div className="data">
                          <input
                            value={descendingStudentadmin}
                            onChange={(e) =>
                              checkBoxHandler2(e, "descendingStudentadmin")
                            }
                            checked={descendingStudentadmin}
                            type="checkbox"
                            name="descendingStudentadmin"
                            id=""
                          />{" "}
                          <span>Z - A</span>
                        </div>{" "}
                      </div>
                      {/* <div className='access-level-inner_right'>
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>4-week</span>
                        </div>{' '}
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>8-week</span>
                        </div>{' '}
                        <div className='data'>
                          <input type='checkbox' name='' id='' />
                          <span>12-week</span>
                        </div>{' '}
                      </div> */}
                    </div>
                  </div>
                  <div className="sorting">
                    <br />
                    <span className="header">Region / Country</span>
                    <div className="data">
                      <Box>
                        <UnstyledSelectGrouping />
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* Student ends */}
          </Box>
        </div>
      </div>
    </>
  );
};

export default ManageAccounts;
