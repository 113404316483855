import React, { useState, createContext, useEffect, useContext } from "react";
import { consoleLog } from "../utils/helper";
import api from "../services/api";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    getUserDataFromCookie();
  }, []);

  const getUserDataFromCookie = async () => {
    try {
      const res = await api.get(`/api/v1/user/profile`);
      if (res && res.ok) {
        setUser(res.data);      
        setIsAuthenticated(true);
      }
    } catch (err) {
      consoleLog(err);
      setIsAuthenticated(false);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isAuthenticated,
        setIsAuthenticated,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserContextProvider;
