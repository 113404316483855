import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Classroom from '../../components/SchoolHome/classroom'
import License from '../../components/SchoolHome/license'
import { SetProps, TabPanel } from '../../components/Tabs'
import InspectTeacher from '../../components/TeacherHome/inspectTeacher'
import BreadCrumb from '../../components/BreadCrumb'
import BreadCrumbList from '../../components/BreadCrumbList'
import { InfoContext } from '../../components/Context/InfoContext'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Sidebar from '../../components/Sidebar'

export default function TeacherHome({ match }) {
  const location = useLocation()
  const { name } = location.state

  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const query = localStorage.getItem('query')
  const classrooms = useSelector((state) => state.siteadmin.classrooms)
  const filteredClassrooms = classrooms.filter((res) => res.school === query)

  const licenses = useSelector((state) => state.siteadmin.licenses)
  const filteredLicenses = licenses.filter((res) => res.schoolId === query)

  const breadcrumbList = [
    {
      name: 'Dashboard',
      to: '/dashboard',
    },
    {
      name: name,
      to: `/dashboard/school/${name}`,
    },
    {
      name: match.params.teacher,
      to: `/dashboard/teacher/${match.params.teacher}`,
    },
  ]

  return (
    <InfoContext.Provider value={{ schoolName: match.params.school }}>
      <Sidebar />
      <div className='main'>
        <Box sx={{ width: '100%', padding: '38px' }}>
          <BreadCrumbList values={breadcrumbList} />
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='Classroom' {...SetProps(0)} />
              <Tab label='Teacher' {...SetProps(1)} />
              <Tab label='License' {...SetProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Classroom resultData={filteredClassrooms} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <InspectTeacher />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <License resultData={filteredLicenses} />
          </TabPanel>
        </Box>
      </div>
    </InfoContext.Provider>
  )
}
