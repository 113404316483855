import React from "react";
import cityBg from "../../../assets/cityBg.png";
import avatar1 from "../../../assets/avatar1.png";
import remove from "../../../assets/remove.svg";

const EditGameContentMain = () => {
  const data = [
    {
      cityImage: cityBg,
      avatarImg: avatar1,
      characterName: "Vanessa",
      dialog: "With the proper set-up and support, it’s time to get to work.",
      conversation: [
        {
          name: "Vanessa",
          fileName: "File1.txt",
        },
        {
          name: "Sean",
          fileName: "File2.txt",
        },
      ],
      bgImage: "Hongkong_streetview.png",
    },

    {
      cityImage: cityBg,
      avatarImg: avatar1,
      characterName: "Sean",
      dialog:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
      conversation: [
        {
          name: "XYZ",
          fileName: "File3.txt",
        },
        {
          name: "ABC",
          fileName: "File4.txt",
        },
      ],
      bgImage: "TungChung_streetview.png",
    },
  ];

  return (
    <div className="editContentMainDiv">
      <div className="topBar">
        <span>Game mangement/ week 1/ game content</span>
      </div>

      {data && data.length > 0
        ? data.map((d, index) => {
            return (
              <div className="conversationDivMain" key={index}>
                <div className="leftDiv">
                  <div className="titleText">Conversation</div>
                  <div className="fileMainContainer">
                    {/* <div className="fileDivMain">
                      <span>Vanessa</span>
                      <div className="fileNameDiv">File1.txt</div>
                      <div className="removeDiv">
                        <img src={remove} alt="remove icon" />
                      </div>
                    </div> */}

                    {d.conversation && d.conversation.length > 0
                      ? d.conversation.map((c, i) => {
                          return (
                            <div className="fileDivMain" key={i}>
                              <span>{c.name}</span>
                              <div className="fileNameDiv">{c.fileName}</div>
                              <div className="removeDiv">
                                <img src={remove} alt="remove icon" />
                              </div>
                            </div>
                          );
                        })
                      : ""}
                  </div>

                  <button className="uploadBtn">Upload new</button>

                  <div className="dividerLine"></div>

                  <div className="titleText">Background image</div>
                  <div className="fileMainContainer">
                    <div className="fileDivMain">
                      <div className="fileNameDivFull">{d.bgImage}</div>
                      <div className="removeDiv">
                        <img src={remove} alt="remove icon" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rightDiv">
                  <div className="previewText">Preview</div>
                  <div className="imageDiv">
                    <img className="bgImage" src={d.cityImage} />
                    <img className="avatarImg" src={d.avatarImg} />
                    <div className="dialogeText">
                      <div className="charatcterName">{d.characterName}</div>
                      <span>{d.dialog}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
      {/* 
      <div className="conversationDivMain">
        <div className="leftDiv">
          <div className="titleText">Conversation</div>
          <div className="fileMainContainer">
            <div className="fileDivMain">
              <span>Vanessa</span>
              <div className="fileNameDiv">File1.txt</div>
              <div className="removeDiv">
                <img src={remove} alt="remove icon" />
              </div>
            </div>

            <div className="fileDivMain">
              <span>Sean</span>
              <div className="fileNameDiv">File2.txt</div>
              <div className="removeDiv">
                <img src={remove} alt="remove icon" />
              </div>
            </div>
          </div>

          <button className="uploadBtn">Upload new</button>

          <div className="dividerLine"></div>

          <div className="titleText">Background image</div>
          <div className="fileMainContainer">
            <div className="fileDivMain">
              <div className="fileNameDivFull">Hongkong_streetview.png</div>
              <div className="removeDiv">
                <img src={remove} alt="remove icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="rightDiv">
          <div className="previewText">Preview</div>
          <div className="imageDiv">
            <img className="bgImage" src={cityBg} />
            <img className="avatarImg" src={avatar1} />
            <div className="dialogeText">
              <div className="charatcterName">Vanessa</div>
              <span>
                With the proper set-up and support, it’s time to get to work.
              </span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default EditGameContentMain;
