import React, { useContext, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { Button, Typography } from "@mui/material";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Link } from "react-router-dom";
import { StudentContext } from "../Context/StudentContext";
import api from "../../services/api";
import { toast } from "react-toastify";
import { WarningModal, UpdateStudentModal } from "../../components/Modal";
import ConfirmButton from "../Buttons/ConfirmButton";
import CancelButton from "../Buttons/CancelButton";
const style = {
  border: "1px solid gray",
  width: 38,
  padding: 5,
  borderRadius: 20,
  cursor: "pointer",
};

export default function ClassroomInspectCell({ row }) {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [close1, setClose1] = useState(false);
  const [todelete, setToDelete] = useState();

  const openModal = (id) => {
    setToDelete(id);
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setClose(true);
    // console.log('hello')
  };
  const openModal1 = (id) => {
    setToDelete(id);
    setOpen1(true);
    setName(row.name);
    setEmail(row.email);
  };
  const closeModal1 = () => {
    setOpen1(false);
    setClose1(true);
    // console.log('hello')
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  // const formHandler = (e) => {
  //   setFormState({ ...formState, [e.target.name]: e.target.value })
  // }
  const updateStudent = async (id) => {
    try {
      const res = await api.patch(`/api/v1/update/student/${id}`);
      if (res && res.ok) {
        setToDelete();
        toast.success("Student Updated Successfully");
        window.location.reload();
      }
    } catch (error) {}
  };

  const Modal = () => (
    <WarningModal
      open={open}
      close={close}
      // heading='
      // New Account'
    >
      <div className="confirm-close-btns">
        <ConfirmButton>
          <div className="open-btn" onClick={() => deleteStudent(todelete)}>
            Confirm
          </div>
        </ConfirmButton>
        <CancelButton>
          <div className="close-btn" onClick={closeModal}>
            Cancel
          </div>
        </CancelButton>
      </div>
    </WarningModal>
  );
  const Modal2 = () => (
    <div
      className="studentwarn"
      // New Account'
    >
      <div className="fields">
        <input
          type="text"
          name="name"
          value={name}
          className="name"
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          name="email"
          value={email}
          className="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="confirm-close-btns">
        <ConfirmButton>
          <div className="open-btn" onClick={() => updateStudent(todelete)}>
            Update
          </div>
        </ConfirmButton>
        <CancelButton>
          <div className="close-btn" onClick={closeModal1}>
            Cancel
          </div>
        </CancelButton>
      </div>
    </div>
  );
  const meanScores = (scores) => {
    const sumWithInitial = scores.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
    return (sumWithInitial / scores.length).toFixed(0);
  };
  const Info = useContext(StudentContext);

  const deleteStudent = async (id) => {
    try {
      const res = await api.delete(`/api/v1/siteadmin/remove/student/${id}`);

      if (res && res.ok) {
        toast.success("Student Deleted Successfully");
        window.location.reload();
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {console.log("row is", row)}
      <TableCell align="left">{row?.name}</TableCell>
      <TableCell align="left">{row.email}</TableCell>
      <TableCell align="left">{row.index}</TableCell>
      <TableCell align="left">
        {row?.studentGrades.length > 0 && meanScores(row.studentGrades)}
      </TableCell>
      <TableCell align="left">
        {row?.totalCampnayValuation?.toFixed(0)}
      </TableCell>
      {open1 && <Modal2 />}
      <TableCell align="left">
        <Typography
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: 100,
            // border: '1px solid red',
          }}
        >
          <Link
            to={`/dashboard/student/${row?.schoolId}/${Info.Info.classroom}/${row._id}`}
          >
            <div style={style}>
              <InsertChartOutlinedIcon style={{ color: "gray" }} />
            </div>
          </Link>
          {/* <div style={style} onClick={() => openModal1(row._id)}>
            <ModeEditOutlineOutlinedIcon style={{ color: 'gray' }} />
          </div> */}
          <div style={style} onClick={() => openModal(row._id)}>
            <CloseOutlinedIcon style={{ color: "gray" }} />
          </div>
        </Typography>
        <Modal />
      </TableCell>
    </>
  );
}
