import api from "./api";

import {
  adminGetAccountSiteadmin,
  adminGetAccountStudent,
  adminGetAccountTeacher,
  adminGetAccountSchool,
  adminGetSchools,
  adminGetSchool,
  adminGetSchoolTeacher,
  adminGetMediaText,
  adminGetSchoolLicense,
  adminGetClassrooms,
  adminDeleteClassrooms,
  adminGetAllStudentsofClass,
  adminGetRegions,
  adminGetTeachers,
  adminGetPlatformManagement,
  adminGetLicenses,
  adminAddClassrooms,
  adminGetMedia,
  adminGetCalendarEvents,
  adminAddCalendarEvents,
  adminUpdateCalendarEvents,
  adminGetDecisionTreeQuestions,
  adminGetWeeklyPerformance,
  adminGetGameCharacterMostPicked,
  adminGetAverage,
  adminGetGameParameters,
  adminGetGameCharacters,
  adminGetSystemRecords,
  beginApiCall,
  endApiCall,
} from "../store/actions/siteadminActions";

const adminService = {
  // Schools
  getSchools: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/view/all/school";
      const res = await api.get(path);
      dispatch(adminGetSchools(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getSiteAdmin: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/all/siteadmin";
      const res = await api.get(path);
      dispatch(adminGetAccountSiteadmin(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getSchoolAdmin: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/all/school";
      const res = await api.get(path);
      dispatch(adminGetAccountStudent(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getTeacherAdmin: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/all/teacher";
      const res = await api.get(path);
      dispatch(adminGetAccountTeacher(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getStudentAdmin: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/all/student";
      const res = await api.get(path);
      dispatch(adminGetAccountSchool(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getShool: async (_id, dispatch) => {
    try {
      let path = `api/v1/siteadmin/view/all/class/${_id}`;
      const res = await api.get(path);
      dispatch(adminGetSchool(res.classRooms));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPlatformMangement: async (dispatch) => {
    try {
      let path = `api/v1/siteadmin/schoolContents/view`;
      const res = await api.get(path);
      dispatch(adminGetPlatformManagement(res.classRooms));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getWeeklyPerformance: async (filter, dispatch) => {
    try {
      let path = `api/v1/siteadmin/weekly/performance`;
      const res = await api({ method: "GET", url: path, data: filter });
      const data = [];
      data.push(res.totalAvg);
      data.push(res.quizResponse);
      data.push(res.averageSubmittionTime);
      dispatch(adminGetWeeklyPerformance(data));
      return Promise.resolve(data);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getShoolTeacher: async (_id, dispatch) => {
    try {
      let path = `api/v1/siteadmin/view/all/teacher/${_id}`;
      const res = await api.get(path);
      dispatch(adminGetSchoolTeacher(res.teachers));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getShoolLicense: async (_id, dispatch) => {
    try {
      let path = `api/v1/siteadmin/all/license/${_id}`;
      const res = await api.get(path);
      dispatch(adminGetSchoolLicense(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  //media
  getMedia: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/media/all";
      const res = await api.get(path);
      dispatch(adminGetMedia(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getMediaText: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/pdf/all";
      const res = await api.get(path);
      dispatch(adminGetMediaText(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // Average
  getAverage: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/overall/grade";
      const res = await api.get(path);
      dispatch(adminGetAverage(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  //Get game parameters
  getGameParameters: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/overall/game-para";
      const res = await api.get(path);
      dispatch(adminGetGameParameters(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getMostPickedCharacter: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/most-picked/chara";
      const res = await api.get(path);
      dispatch(adminGetGameCharacterMostPicked(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Classrooms
  getClassrooms: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/view/all/class";
      const res = await api.get(path);
      dispatch(adminGetClassrooms(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  addClassrooms: async (data, dispatch) => {
    try {
      let path = "api/v1/siteadmin/add/classroom/school";
      const res = await api.post(path, data);
      dispatch(adminAddClassrooms(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  deleteClassroom: async (_id, dispatch) => {
    try {
      const res = await api.delete(`/api/v1/siteadmin/delete/class/${_id}`);
      dispatch(adminDeleteClassrooms(_id));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getAllStudentsOfClass: async (_id, dispatch) => {
    try {
      let path = `api/v1/siteadmin/view/all/class/${_id}/student`;
      const res = await api.get(path);
      console.log("response is", res);
      dispatch(adminGetAllStudentsofClass(res.data.student));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Teachers
  getTeachers: async (dispatch) => {
    try {
      let path = `api/v1/siteadmin/view/all/teacher`;
      const res = await api.get(path);
      dispatch(adminGetTeachers(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Regions
  getRegions: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/view/all/region";
      const res = await api.get(path);
      dispatch(adminGetRegions(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Licenses
  getLicenses: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/all/license";
      const res = await api.get(path);
      dispatch(adminGetLicenses(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  //Calendar
  getCalendarEvents: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/all/event";
      const res = await api.get(path);
      dispatch(adminGetCalendarEvents(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  addCalendarEvents: async (data, dispatch) => {
    try {
      let path = "api/v1/siteadmin/add/event";
      const res = await api.post(path, data);
      dispatch(adminAddCalendarEvents(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  updateCalendarEvents: async (_id, data, dispatch) => {
    try {
      let path = `api/v1/siteadmin/edit/event/${_id}`;
      const res = await api.patch(path, data);
      dispatch(adminUpdateCalendarEvents(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // Analytics
  // getDecisionTreeQuestions: async (dispatch) => {
  //   try {
  //     let path = "api/v1/siteadmin/all/decision-tree";
  //     const res = await api.get(path);
  //     dispatch(adminGetDecisionTreeQuestions(res.data));
  //     return Promise.resolve(res);
  //   } catch (error) {
  //     return Promise.reject(error);
  //   }
  // },

  addLesson: async (data, dispatch) => {
    try {
      let path = "api/v1/siteadmin/add/event";
      const res = await api.post(path, data);
      dispatch(adminAddCalendarEvents(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getGameCharacters: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/view/all/game-character";
      const res = await api.get(path);
      dispatch(adminGetGameCharacters(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  getSystemRecords: async (dispatch) => {
    try {
      let path = "api/v1/siteadmin/csv/all";
      const res = await api.get(path);
      dispatch(adminGetSystemRecords(res.data));
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  beginApiCallSiteadmin: async (dispatch) => {
    try {
      console.log("started ");
      dispatch(beginApiCall());
      // return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error);
    }
  },
  endApiCallSiteadmin: async (dispatch) => {
    try {
      dispatch(endApiCall());
      // return Promise.resolve(res)
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

export default adminService;
