import React, { useState } from 'react'
import TableCell from '@mui/material/TableCell'
import playIcon from '../../assets/playIcon.svg'
import Modal from '@mui/material/Modal'
import config from '../../config'
import api from '../../services/api'
import Collapse from '@mui/material/Collapse'
import TableRow from '@mui/material/TableRow'

export default function AnalyticsMediaCell({ row }) {
  //   let videos = []
  const [open, setOpen] = useState(false)
  const [collapase, setCollapse] = useState(false)

  const openModal = () => {
    setOpen(true)
  }
  const closeModal = () => {
    setOpen(false)
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '400px',
    // height: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  }
  const [videoSource, setVideoSource] = useState()
  const playVideo = async (id, source) => {
    setOpen(true)
    setVideoSource(source)
    await api.get(`/api/v1/siteadmin/media/play/${id}`)
  }
  const handleAccordion = () => {
    setCollapse((prev) => !prev)
  }
  const buttonCollapse = {
    color: '#EF5B2C',
    border: '0',
    textDecoration: 'underline',
    fontSize: '12px',
    background: 'none',
  }
  return (
    <>
      <>
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <div style={style}>
            <div className='video-content-inner-modal'>
              <video id={row.id} autoPlay controls className='video-content-1'>
                <source src={videoSource} type='video/mp4' />
              </video>
              {/* <img
              onClick={() => playVideoOnModal(row.id)}
              src={play}
              alt='Play'
            /> */}
            </div>
          </div>
        </Modal>

        <TableCell align='left' sx={{ fontWeight: '600 !important' }}>
          <div className='video-content'>
            <div className='video-content-inner'>
              {row.source ? (
                <video id={row.id} className='video-content-1'>
                  <source
                    src={config.app.apiUrl + '/api/' + row.source}
                    type='video/mp4'
                  />
                </video>
              ) : null}
              {row.source ? (
                <img
                  onClick={() =>
                    playVideo(row.id, config.app.apiUrl + '/api/' + row.source)
                  }
                  src={playIcon}
                  alt='Play'
                />
              ) : null}
            </div>
            {row.source?.split('/').pop() || 'N/a'}
          </div>
        </TableCell>
        <TableCell align='left'>{row.length}</TableCell>
        <TableCell align='left'>{row.rewatchView} </TableCell>
        <TableCell align='left'>
          <button style={buttonCollapse} onClick={handleAccordion}>
            {collapase ? 'Collapse' : 'View'}
          </button>
        </TableCell>
      </>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={collapase} timeout='auto' unmountOnExit>
            sdfsdfdsfdsfsf
          </Collapse>
        </TableCell>
      </TableRow>

      {/* {collapase ? <div>{row.id}</div> : null} */}
    </>
  )
}
