import React from 'react'
import { Divider } from '@mui/material'
import { SetProps, TabPanel } from '../../components/Tabs'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import AddGameContent from '../../components/Platform/Game/AddGameContent'
import Game from '../../components/Platform/Game/game'
import Database from '../../components/Platform/Database/database'
import Lesson from '../../components/Platform/Lesson/lesson'
import Sidebar from '../../components/Sidebar'

const AddGameHome = () => {
  const [value, setValue] = React.useState(1)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Sidebar />
      <div className='main addContentHomeMainDiv'>
        <div className='heading-main'>
          Platform Management
          <Divider sx={{ width: '95%', borderBottomWidth: 2, mt: 1 }} />
        </div>

        <div className='container-page'>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                sx={{
                  '& .MuiButtonBase-root': {
                    backgroundColor: '#DADADA ',
                    width: 1007,
                  },
                  '& .Mui-selected': {
                    backgroundColor: '#EF5B2C',
                    color: 'white !important',
                    borderRadius: 1,
                  },

                  '& .MuiTabs-indicator': {
                    borderBottomColor: 'white !important',
                  },
                }}
                value={value}
                onChange={handleChange}
              >
                <Tab label='Lesson Management' {...SetProps(0)} />
                <Tab label='Game Management' {...SetProps(1)} />
                <Tab label='Database' {...SetProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Lesson />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AddGameContent />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Database />
            </TabPanel>
          </Box>
        </div>
      </div>
    </>
  )
}

export default AddGameHome
