import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import warning from "../assets/warning.svg";
import ReactApexChart from "react-apexcharts";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const GlobalModal = (props) => {
  const [height, setHeight] = useState(200);
  useEffect(() => {
    if (props.bigModel === "yes") {
      setHeight(400);
    }
  }, []);
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container" style={{ minHeight: `${height}px` }}>
          <div
            className={
              props.type === "upload"
                ? "modal-container-inner-upload"
                : "modal-container-inner"
            }
            style={{ minHeight: `${height + 100}px` }}
          >
            <div className="confirm-dialog-box">
              <div
                className={
                  props.type === "upload"
                    ? "modal-header-upload"
                    : "modal-header"
                }
              >
                {props.heading}
              </div>
            </div>
            {props.children}
          </div>
        </div>
      </Modal>
    </div>
  );
};
const WarningModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container-warn">
          <div className="modal-container-inner-warn">
            <div className="modal-header">{props.heading}</div>
            <div className="warning">
              <div className="warning-left">
                <img src={warning} alt="Warning" />
              </div>
              <div className="warning-right">
                Are you sure you want to delete this person?
              </div>
            </div>

            {props.children}
          </div>
        </div>
      </Modal>
    </div>
  );
};
const UpdateStudentModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container-warn">
          <div className="modal-container-inner-warn">
            <div className="modal-header">{props.heading}</div>
            <br />

            {props.children}
          </div>
        </div>
      </Modal>
    </div>
  );
};
const AnalyticsModal = (props) => {
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container-analytics">
          <div className="modal-container-inner-analytics">
            <div className="modal-header" style={{ marginBottom: "45px" }}>
              {/* {props.heading} */}
              Module questions analytics
            </div>
            {/* <button className="btn-analytics">Export</button> */}
          </div>
          {props.children}
        </div>
      </Modal>
    </div>
  );
};

const DistributionCurveModal = (props) => {
  const { values, open, close, heading, options } = props;
  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-container-curve">
          <div className="modal-container-inner-curve">
            <div className="modal-header" style={{ marginBottom: "45px" }}>
              {heading}
            </div>
            <button className="btn-analytics">Export</button>
          </div>
          <div className="curve-value-container">
            <div className="curve-values">
              <span> Average : {values.average}</span>
              <span>Median : {values.median}</span>
            </div>

            <div>
              <div>
                <strong>School</strong>
              </div>
              <div style={{ display: "flex" }}>
                <div className="input-box-outer-curve">
                  <select style={{ padding: 5 }} className="input-box">
                    <option>Nepal</option>
                    <option>China</option>
                    <option>UK</option>
                    <option>US</option>
                  </select>
                </div>
                &nbsp;&nbsp;
                <div>
                  <input
                    className="input-box"
                    placeholder="School name"
                    type="text"
                  />
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <ReactApexChart
            options={options}
            series={options.series}
            width={1000}
            height={300}
          />

          <div style={{ marginLeft: "45%" }}>
            <button onClick={close} className="back-btn back-btn-1">
              Back
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export {
  GlobalModal,
  UpdateStudentModal,
  WarningModal,
  AnalyticsModal,
  DistributionCurveModal,
};
