import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../components/Tabs";
import Box from "@mui/material/Box";
import { SetProps } from "../../components/Tabs";
import TableMain from "../Table";
import ReactApexChart from "react-apexcharts";
import DistributionCurve from "./distributionCurve";
import { useDispatch, useSelector } from "react-redux";
import siteadminService from "../../services/siteadminService";
import AnalyticsSvg from "../../assets/analytics-inactive.svg";
import ExportModal from "./ExportModal";
import TableDecisionTree from "./TableDecisionTree";
import { jsPDF } from "jspdf";
import BoxModal from "./BoxModal";
import api from "../../services/api";
import { set } from "date-fns";
import config from "../../config";
import { consoleLog } from "../../utils/helper";
import Pagination from "@mui/material/Pagination";
import { NumericFormat } from "react-number-format";

const dynamicColors = function () {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};

const series = [44, 33, 54, 45, 24, 45];
const characterOptions = {
  chart: {
    width: 320,
    type: "pie",
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  // colors: ["#939393", "#C4C4C4", "#AEAEAE", "#E2E2E2", "#C4C4C4", "#AEAEAE"],
  stroke: {
    width: 0.2,
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: ["#111"],
    },
  },
  fill: {
    type: "image",
    opacity: 0.85,
    image: {
      src: [],
      width: 25,
      imagedHeight: 25,
    },
  },
};

const boosterShopOptions = {
  chart: {
    width: 320,
    type: "pie",
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  // colors: ["#939393", "#C4C4C4", "#AEAEAE", "#E2E2E2", "#C4C4C4", "#AEAEAE"],
  stroke: {
    width: 0.2,
  },
  dataLabels: {
    enabled: true,
    style: {
      colors: ["#111"],
    },
  },
  fill: {
    type: "image",
    opacity: 0.85,
    image: {
      src: [],
      width: 25,
      imagedHeight: 25,
    },
  },
};

const data = [
  {
    number: "001",
    question: "How are you and what do you do?",
    duration: "15m10s",
  },
  {
    number: "001",
    question: "How are you and what do you do?",
    duration: "15m10s",
  },
  {
    number: "001",
    question: "How are you and what do you do?",
    duration: "15m10s",
  },
  {
    number: "001",
    question: "How are you and what do you do?",
    duration: "15m10s",
  },
  {
    number: "001",
    question: "How are you and what do you do?",
    duration: "15m10s",
  },
  {
    number: "001",
    question: "How are you and what do you do?",
    duration: "15m10s",
  },
  {
    number: "001",
    question: "How are you and what do you do?",
    duration: "15m10s",
  },
];
//   table cells
const headCells = [
  {
    id: "number",
    label: "Number",
    numeric: false,
    disablePadding: false,
  },

  {
    id: "question",
    label: "Question",
    numeric: false,
    disablePadding: false,
  },

  {
    id: "duration",
    label: "Average reading duration",
    numeric: true,
    disablePadding: false,
  },
];

const AnalyticsGame = ({ gameParameter, mostPickedCharacter }) => {
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [exportModalOpen, setExportModalOpen] = React.useState(false);
  const [characterBoxOpen, setCharacterBoxOpen] = React.useState(false);
  const [mostBought, setMostBought] = useState("");
  const [mostBoughtDistribution, setMostBoughtDistribution] = useState([]);
  const [mostBoughtArray, setMostBoughtArray] = useState([]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const modalOpen = () => {
    setOpen(true);
  };

  const modalClose = () => {
    setOpen(false);
  };

  const exportModalClose = () => {
    setExportModalOpen(false);
  };

  const characterBoxClose = () => {
    setCharacterBoxOpen(false);
  };

  const dispatch = useDispatch();

  const [mostPickedCharacterName, setMostPickedCharacter] = useState({});
  const [mostPickedCharactersNumbers, setmostPickedCharactersNumbers] =
    useState([]);
  // get decision questions
  const [decisionqns, setDecisionqns] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const getDecisionQuestions = async () => {
    try {
      setLoaded(false);
      const data = await api.get(
        `/api/v1/siteadmin/all/decision-tree?p=${pageNumber}`
      );

      if (data && data.ok) {
        setDecisionqns(data.data);
        setLoaded(true);
      }
    } catch (err) {
      setLoaded(false);

      console.log("err", err);
    }
  };
  useEffect(() => {
    getDecisionQuestions();
  }, [pageNumber]);

  //     const res = await api.get(path);

  const decision_questions = useSelector(
    (state) => state.siteadmin.decision_questions
  );

  const getMostBoughtItem = async () => {
    try {
      const res = await api.get("/api/v1/siteadmin/most-bought-item");
      if (res && res.ok) {
        setMostBought(res.data[0].itemType);
        console.log(res.data[0].itemType);
      }
      const res1 = await api.get("/api/v1/siteadmin/booster-item/percentage");
      if (res1 && res1.ok) {
        setMostBoughtDistribution(res1.data);
        console.log(res1.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getMostBoughtItem();
  }, []);

  useEffect(() => {
    const arr = [];
    if (mostBoughtDistribution.length) {
      mostBoughtDistribution.forEach((item) => {
        arr.push(item.percentage);
        boosterShopOptions.fill.image.src.push(`${item.image}`);
      });
      setMostBoughtArray(arr);
    }
  }, [mostBoughtDistribution]);

  console.log("====data");
  console.log(mostBoughtDistribution);
  console.log(boosterShopOptions);
  console.log("====data");

  const [isLoaded, setLoaded] = useState(false);

  let characterNewOptions = { ...characterOptions };
  const [charactersare, setCharactersAre] = useState([]);
  console.log(mostPickedCharacter);
  useEffect(() => {
    if (mostPickedCharacter && mostPickedCharacter.length) {
      const highestPick = mostPickedCharacter.map((nums) => {
        return nums.noOfPicks;
      });
      const charactersAre = mostPickedCharacter.map((nums) => {
        characterNewOptions.fill.image.src.push(
          `${config.app.apiUrl}/api/uploads/admin/character/${nums.largeImg}`
        );
        return nums.name[0]?.name;
      });
      characterNewOptions.colors = highestPick.map(() => dynamicColors());
      const getMaxNum = Math.max(...highestPick);
      const getMostPickCharater = mostPickedCharacter.find(
        (item) => item.noOfPicks === getMaxNum
      );
      console.log(getMostPickCharater);
      setMostPickedCharacter(getMostPickCharater);
      setCharactersAre(charactersAre);
      console.log("highest pick", highestPick, mostPickedCharacter);
      setmostPickedCharactersNumbers(highestPick);
    }
  }, [mostPickedCharacter]);

  // useEffect(() => {
  //   async function getAllQuestions() {
  //     try {
  //       siteadminService.getDecisionTreeQuestions(dispatch)
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  //   getAllQuestions()
  //   setLoaded(true)
  // }, [])

  const downloadPdfDocument = (tabIndex, data, headCells, exportTitle) => {
    const option = {
      orientation: "portrait",
      unit: "mm",
      format: "a4",
    };
    const newDoc = new jsPDF(option);
    newDoc.setFontSize(16);
    const title = exportTitle;
    const headers = [headCells.map((item) => item.label)];
    let newData;
    if (tabIndex === 1) {
      newData = data.map((item) => {
        return [
          item.number || "n/a",
          item.question,
          item.averageReadingTime || "0",
        ];
      });
    }
    if (tabIndex === "0") {
      newData = data.map((item, idx) => {
        return [
          idx + 1,
          item.field,
          item.name,
          item.gender,
          item.noOfPicks,
          item.welcomeDialogue,
          item.desc,
        ];
      });
    }
    let content = {
      startY: 20,
      head: headers,
      body: newData,
    };
    newDoc.text(title, 40, 40);
    newDoc.autoTable(content);
    newDoc.save(`${title}.pdf`);
    return;
  };

  const exportData = (whole, individual) => {
    if (value === 1) {
      const headCells = [
        { label: "Number" },
        { label: "Questions" },
        { label: "Average Reading time" },
      ];
      if (whole && individual) {
        const data = decision_questions.map((res) => res.decisionTree);
        let newData = data.flat(Infinity);
        downloadPdfDocument(1, newData, headCells, "Decision_Tree");
        return;
      }
      if (whole) {
        const data = decision_questions.map((res) => res.decisionTree);
        let newData = data.flat(Infinity);
        downloadPdfDocument(1, newData, headCells, "Decision_Tree");
        return;
      }
      if (individual) {
        return;
      }
    }

    if (value === 0) {
      const headCells = [
        { label: "SN" },
        { label: "Field" },
        { label: "Name" },
        { label: "Gender" },
        { label: "Number of Picks" },
        { label: "Welcome Dialogue" },
        { label: "Description" },
      ];
      if (whole && individual) {
        downloadPdfDocument(
          "0",
          mostPickedCharacter,
          headCells,
          "MostPickedCharacter"
        );
        return;
      }
      if (whole) {
        downloadPdfDocument(
          "0",
          mostPickedCharacter,
          headCells,
          "MostPickedCharacter"
        );
        return;
      }
      if (individual) {
        return;
      }
    }
  };
  const labels = ["Team A", "Team B"];
  return (
    <div className="analyticsMediaContainer">
      <div style={{ width: "100%", overflow: "hidden" }}>
        <div className="tabs-list">
          <Tabs
            sx={{ width: "100%" }}
            value={value}
            indicatorColor="secondary"
            onChange={handleChange}
          >
            <Tab label="Game Element" {...SetProps(0)} />
            <Tab label="Decision tree questions" {...SetProps(1)} />
          </Tabs>
          {/* <button
            className='btn-analytics'
            onClick={() => setExportModalOpen(true)}
          >
            Export
          </button> */}
        </div>
        <ExportModal
          open={exportModalOpen}
          close={exportModalClose}
          getData={exportData}
        />
        <TabPanel value={value} index={0}>
          <div className="">
            <BoxModal
              open={characterBoxOpen}
              close={characterBoxClose}
              title="Most Picked Character"
              data={mostPickedCharacter}
            />
            {console.log("most is", mostPickedCharacter)}
            <DistributionCurve open={open} close={modalClose} />
            <Box sx={{ width: "100%" }}>
              <div className="tabpanel-content">
                <span className="header"></span>
                <div style={{ width: "100%" }} className="top-section">
                  <div className="top-section-left ">
                    <div className="top-section-game-left-top spacing">
                      <div className="upper">Average company value</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <img src={AnalyticsSvg} className="icon book" alt="" />
                        <div
                          className="lower large-font"
                          title={gameParameter?.countValuation}
                          style={{ color: "#EF5B2C" }}
                        >
                          {/* {Math.round(gameParameter?.countValuation) > 1000
                            ? Math.round(gameParameter?.countValuation / 1000) +
                              'k'
                            : Math.round(gameParameter?.countValuation)} */}

                          <NumericFormat
                            value={gameParameter?.countValuation}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            className="mx-2"
                            decimalScale={0}
                            allowNegative
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      onClick={() => setCharacterBoxOpen(true)}
                      className="top-section-game-left-bottom spacing "
                    >
                      <div className="upper">Mostly picked character</div>
                      <div className="lower">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={AnalyticsSvg}
                            className="icon book"
                            alt=""
                          />
                          {mostPickedCharacterName
                            ? mostPickedCharacterName &&
                              mostPickedCharacterName.name
                              ? mostPickedCharacterName?.name[0].name
                              : ""
                            : ""}
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="top-section-left  ">
                    <div
                      // onClick={modalOpen}
                      className="top-section-game-left-top spacing"
                    >
                      <div className="upper">Average company's reputation</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <img src={AnalyticsSvg} className="icon book" alt="" />
                        <div
                          className="lower large-font"
                          title={gameParameter?.countReputation}
                          style={{ color: "#EF5B2C" }}
                        >
                          <NumericFormat
                            value={gameParameter?.countReputation}
                            displayType={"text"}
                            thousandSeparator={true}
                            // prefix={'$'}
                            className="mx-2"
                            decimalScale={0}
                            allowNegative
                          />
                        </div>
                      </div>
                    </div>
                    <div className="top-section-game-left-bottom spacing ">
                      <div className="upper">
                        Mostly bought item at "booster time"
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <img src={AnalyticsSvg} className="icon book" alt="" />
                        <div className="lower">{mostBought}</div>
                      </div>
                    </div>
                  </div>

                  <div className="top-section-left  ">
                    <div
                      // onClick={modalOpen}
                      className="top-section-game-left-top spacing"
                    >
                      <div className="upper">
                        Average employee (satisfaction)
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <img src={AnalyticsSvg} className="icon book" alt="" />
                        <div
                          className="lower large-font"
                          title={gameParameter?.countSatisfaction}
                          style={{ color: "#EF5B2C" }}
                        >
                          <NumericFormat
                            value={gameParameter?.countSatisfaction}
                            displayType={"text"}
                            thousandSeparator={true}
                            // prefix={'$'}
                            className="mx-2"
                            decimalScale={0}
                            allowNegative
                          />
                        </div>
                      </div>
                    </div>
                    <div className="top-section-game-left-bottom spacing ">
                      <div className="upper">
                        Average reading time of conversation
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <img src={AnalyticsSvg} className="icon book" alt="" />
                        <div className="lower large-font">2m10s</div>
                      </div>
                    </div>
                  </div>

                  <div className="top-section-left  ">
                    <div
                      // onClick={modalOpen}
                      className="top-section-game-left-top spacing"
                    >
                      <div className="upper">Average remaining cash</div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <img src={AnalyticsSvg} className="icon book" alt="" />
                        <div
                          className="lower large-font"
                          title={gameParameter?.countCash}
                          style={{ color: "#EF5B2C" }}
                        >
                          <NumericFormat
                            value={gameParameter?.countCash}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                            className="mx-2"
                            decimalScale={0}
                            allowNegative
                          />
                        </div>
                      </div>
                    </div>
                    <div className="top-section-game-left-bottom spacing ">
                      <div className="upper">
                        Average reading time of decision game question
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "baseline",
                        }}
                      >
                        <img src={AnalyticsSvg} className="icon book" alt="" />
                        <div className="lower large-font">1m2s</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
          <div className="tabpanel-content">
            <div
              style={{
                width: "100%",
                display: "flex ",
                justifyContent: "space-between",
              }}
              className="top-section"
            >
              <div
                style={{ width: "49%", height: "70%" }}
                className="top-section-middle spacing "
              >
                <div className="upper">Character Selection Distribution</div>
                <div className="lower ">
                  <div style={{ marginLeft: "30%" }}>
                    <ReactApexChart
                      options={{
                        ...characterNewOptions,
                        labels: charactersare,
                      }}
                      series={mostPickedCharactersNumbers}
                      // labels={['sdf', 'sdfdf']}
                      type="pie"
                      width={300}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{ width: "49%", height: "70%" }}
                className="top-section-middle spacing "
              >
                <div className="upper">
                  Bought item distribution at "booster time"
                </div>
                <div className="lower ">
                  <div style={{ marginLeft: "30%" }}>
                    <ReactApexChart
                      options={boosterShopOptions}
                      series={mostBoughtArray}
                      type="pie"
                      width={300}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div>
            <Box sx={{ width: "100%" }}>
              <TableDecisionTree resultData={decisionqns} loaded={isLoaded} />
            </Box>
          </div>

          <Pagination
            count={decisionqns?.totalPages - 1}
            onChange={(event, page) => setPageNumber(page)}
            color="primary"
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default AnalyticsGame;
