import React from 'react'
import classnames from 'classnames'
import LoadingSpinnerCss from './LoadingSpinner.css'
const loadingSpinnerCss = classnames.bind(LoadingSpinnerCss)

const LoadingSpinner = (show) => {
  const loadingMaskStyle = loadingSpinnerCss({
    displayNone: !show,
    loadingMask: true,
  })
  return (
    <div className={loadingMaskStyle}>
      <div className='bulbaSpinner'>
        <div className='bulbaSpinnerInner one' />
        <div className='bulbaSpinnerInner two' />
        <div className='bulbaSpinnerInner three' />
      </div>
    </div>
  )
}

export default LoadingSpinner
