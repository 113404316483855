import React, { createContext, useContext, useState } from "react";

export const PlatformManagementContext = createContext();

const PlatformManagementProvider = (props) => {
  const [value, setValue] = useState(0); // for lesson mgmt, game mgmt, database tab
  const [gameTabValue, setGameTabValue] = useState(0); // tab for 4,8,12 weeks (inner tab)
  const [lessonMgmtTabValue, setLessonMgmtValue] = useState(0); // tab for 4,8,12 weeks (inner tab)
  return (
    <PlatformManagementContext.Provider
      value={{
        value,
        setValue,
        gameTabValue,
        setGameTabValue,
        lessonMgmtTabValue,
        setLessonMgmtValue,
      }}
    >
      {props.children}
    </PlatformManagementContext.Provider>
  );
};
export const usePlatform = () => useContext(PlatformManagementContext);
export default PlatformManagementProvider;
