import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SetProps, TabPanel } from "../../Tabs";
import MediaContainer from "./mediaContainer";
import CharacterContainer from "./characterContainer";
import BackgroundImageUpload from "./backgroundImageUpload";
import ShopImageUpload from "./shopImageUpload";

export default function Database({
  gameCharacters,
  LoadCharacters,
  setLoadCharacters,
}) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", paddingTop: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          sx={{
            "& .MuiButtonBase-root": {
              width: 290,
            },
            "& .MuiTabs-indicator": {
              borderBottomColor: "#EF5B2C !important",
            },
          }}
          value={value}
          onChange={handleChange}
        >
          {/* <Tab label="Media" {...SetProps(0)} /> */}
          <Tab label="Character" {...SetProps(0)} />
          <Tab label="Game Background" {...SetProps(1)} />
          <Tab label="Shop image" {...SetProps(2)} />
        </Tabs>
      </Box>
      {/* <TabPanel value={value} index={0}>
        <MediaContainer />
      </TabPanel> */}
      <TabPanel value={value} index={0}>
        <CharacterContainer
          gameCharacters={gameCharacters}
          LoadCharacters={LoadCharacters}
          setLoadCharacters={setLoadCharacters}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BackgroundImageUpload />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <ShopImageUpload />
      </TabPanel>
    </Box>
  );
}
