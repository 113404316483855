import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { SetProps, TabPanel } from "../../Tabs";
import Tab1Lesson from "./tab1Lesson";
import { usePlatform } from "../../../context/PlatformManagement";
import { useParams } from "react-router-dom";

export default function Lesson({
  content1,
  content2,
  content3,
  refresh,
  setRefresh,
}) {
  const { language } = useParams();
  // const [value, setValue] = React.useState(0);
  const { lessonMgmtTabValue, setLessonMgmtValue } = usePlatform();

  const handleChange = (event, newValue) => {
    setLessonMgmtValue(newValue);
  };

  return (
    <div>
      {" "}
      <Box sx={{ width: "100%", paddingTop: 3 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            sx={{
              "& .MuiButtonBase-root": {
                width: 290,
              },
              "& .MuiTabs-indicator": {
                borderBottomColor: "#EF5B2C !important",
              },
            }}
            value={lessonMgmtTabValue}
            onChange={handleChange}
          >
            <Tab label="4 MODULE" {...SetProps(0)} />
            <Tab label="8 MODULE" {...SetProps(1)} />
            <Tab label="12 MODULE" {...SetProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={lessonMgmtTabValue} index={0}>
          <Tab1Lesson
            language={language}
            noOfWeek={4}
            content={content1}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </TabPanel>
        <TabPanel value={lessonMgmtTabValue} index={1}>
          <Tab1Lesson
            language={language}
            noOfWeek={8}
            content={content2}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </TabPanel>
        <TabPanel value={lessonMgmtTabValue} index={2}>
          <Tab1Lesson
            language={language}
            noOfWeek={12}
            content={content3}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </TabPanel>
      </Box>
    </div>
  );
}
