import React from 'react'
import TableCell from '@mui/material/TableCell'

export default function AnalyticsGameCell({ row }) {

  return (
    <>
      <TableCell align='left' sx={{ fontWeight: '600 !important' }}>
        {row.number}
      </TableCell>
      <TableCell align='left'>
        {row.decisionTree.map((res, idx) => res.question)}
      </TableCell>
      <TableCell align='left'>{row.decisionTree.map((res) => res.viewDuration)}</TableCell>
    </>
  )
}
