import React, { useContext, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { Button, Typography } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { InfoContext } from "../Context/InfoContext";
import { GlobalModal } from "../../components/Modal";
import api from "../../services/api";
import siteadminService from "../../services/siteadminService";
import { useDispatch, useSelector } from "react-redux";

import config from "../../config";
export default function TeacherCell({ row, school }) {
  const schoolName = useContext(InfoContext).schoolName;
  const dispatch = useDispatch();

  const navigation = useHistory();

  const InspectOn = (e) => {
    navigation.push({
      pathname: `/dashboard/teacher/${row.username}`,
      state: {
        name: schoolName,
      },
    });
  };

  const [removeStudentModelState, setRemoveStudentModelState] = useState(false);

  const removeClassroomHandler = (row) => {
    console.log(row);
    setRemoveStudentModelState(true);
  };

  const closeRemoveStudentModel = () => {
    setRemoveStudentModelState(false);
  };
  const deleteTeacher = async (id) => {
    try {
      const data = await api.delete(
        `${config.app.apiUrl}/api/v1/siteadmin/delete/teacher/${id}`
      );
      if (data && data.ok) {
        setRemoveStudentModelState(false);

        await siteadminService.getShoolTeacher(school, dispatch);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableCell align="left">{row?.name}</TableCell>
      <TableCell align="left">{row?.classroom?.className}</TableCell>
      <TableCell align="left">
        <Typography>
          {/* <button onClick={InspectOn} className='inspect-btn'>
            Inspect
          </button> */}
          {"    "}
          <button
            className="remove-btn"
            onClick={() => removeClassroomHandler(row)}
          >
            Remove
          </button>
        </Typography>
      </TableCell>

      <GlobalModal
        open={removeStudentModelState}
        close={closeRemoveStudentModel}
        heading=""
      >
        <Typography style={{ width: "390px" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum,
          mattis ultrices vitae, ipsum. Vitae venenatis enim sit accumsan
          pretium at enim faucibus sed. Pellentesque senectus nulla luctus id
          arcu. .
        </Typography>

        <div className="btn-groups">
          <button
            className="export"
            onClick={() => deleteTeacher(row?.user._id)}
          >
            Confirm
          </button>
          <button className="cancel" onClick={closeRemoveStudentModel}>
            Cancel
          </button>
        </div>
      </GlobalModal>
    </>
  );
}
