import React from 'react'
import { DistributionCurveModal } from '../../Modal'


const options = {
    chart: {
        height: 280,
        type: "area"
    },
    stroke: {
        width: 1,
        curve: "smooth"
    },
    dataLabels: {
        enabled: false
    },
    series: [
        {
            name: "Series 1",
            data: [45, 52, 38, 45, 19, 23, 2]
        }
    ],
    fill: {
        type: "gradient",
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 90, 100]
        }
    },
    xaxis: {
        categories: [
            "01 Jan",
            "02 Jan",
            "03 Jan",
            "04 Jan",
            "05 Jan",
            "06 Jan",
            "07 Jan"
        ],

    }
};

export default function DistributionCurve(props) {
    const { open, close } = props
    const heading = "Distribution curve of student's company value"
    const values = {
        average: "680,000",
        median: "630,000"
    }
    return (
        <div>
            <DistributionCurveModal open={open} close={close} heading={heading} values={values} options={options} />
        </div>
    )
}
