import NewSchoolEntry from "./pages/siteadmin/NewSchoolEntry";
import React from "react";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/siteadmin/Dashboard";
import "bootstrap/dist/css/bootstrap.css";
import SchoolHome from "./pages/siteadmin/SchoolHome";
import ContentManagement from "./components/SchoolHome/contentmgnt";
import ManageAccounts from "./pages/siteadmin/ManageAccounts";
import "./styles/globals.scss";
import TeacherHome from "./pages/siteadmin/TeacherHome";
import Student from "./pages/siteadmin/Student";
import Leaderboard from "./pages/siteadmin/Leaderboard";
import ClassroomHome from "./pages/siteadmin/ClassroomHome";
import Login from "./pages/siteadmin/Login";
import ForgotPassword from "./pages/siteadmin/ForgotPassword";
import Calendar from "./pages/siteadmin/Calendar";
import Analytics from "./pages/siteadmin/Analytics";

import Platform from "./pages/siteadmin/Platform";
import AddContentHome from "./pages/siteadmin/AddContentHome";
import PlatformManagementContentEdit from "./pages/siteadmin/PlatformManagementContentEdit";
import AddGameHome from "./pages/siteadmin/AddGameHome";
import GameManagementContentEdit from "./pages/siteadmin/GameManagementContentEdit";
import GameManagementContentEditMain from "./pages/siteadmin/GameManagementContentEditMain";
import WeeklyQuiz from "./pages/siteadmin/WeeklyQuiz";
import PrivateRoute from "./components/PrivateRoute";
import { useUser } from "./context/User";
import { Provider } from "react-redux";
import LoadingSpinner from "../src/components/LoadingSpinner/LoadingSpinner";
import store from "./store";
import PlatformManagementProvider from "./context/PlatformManagement";
import { useDispatch, useSelector } from "react-redux";
import ChooseContentLanguage from "./pages/siteadmin/ChooseContentLanguage";

const App = () => {
  const apiStatus = useSelector((state) => state.loading.apiStatus);

  return (
    <PlatformManagementProvider>
      {apiStatus > 0 && <LoadingSpinner show={true} />}
      <Router>
        <div>
          <Switch>
            <Route path="/" component={Login} exact />
            <Route path="/forgot-password" component={ForgotPassword} exact />

            <PrivateRoute
              path="/dashboard"
              exact
              role={true}
              component={Dashboard}
            />
            <PrivateRoute
              path="/dashboard/new-school-entry"
              exact
              role={true}
              component={NewSchoolEntry}
            />
            <PrivateRoute
              path="/manage-accounts"
              exact
              role={true}
              component={ManageAccounts}
            />

            <Route
              path="/dashboard"
              render={({ match: { url } }) => (
                <>
                  <PrivateRoute
                    path={`${url}/student/:school/:classroom/:student`}
                    exact
                    role={true}
                    component={Student}
                  />
                  <PrivateRoute
                    path={`${url}/content/:school`}
                    exact
                    role={true}
                    component={ContentManagement}
                  />
                  <PrivateRoute
                    path={`${url}/classroom/:classId/:classroom`}
                    exact
                    role={true}
                    component={ClassroomHome}
                  />
                  <PrivateRoute
                    path={`${url}/school/:school`}
                    exact
                    role={true}
                    component={SchoolHome}
                  />
                  <PrivateRoute
                    path={`${url}/teacher/:teacher`}
                    exact
                    role={true}
                    component={TeacherHome}
                  />
                </>
              )}
            />

            <PrivateRoute
              path="/leaderboard"
              exact
              role={true}
              component={Leaderboard}
            />
            <PrivateRoute
              path="/calendar"
              exact
              role={true}
              component={Calendar}
            />

            <Route
              path="/platformManagement"
              render={({ match: { url } }) => (
                <>
                  <PrivateRoute
                    path={`${url}`}
                    exact
                    role={true}
                    component={ChooseContentLanguage}
                  />
                  <PrivateRoute
                    path={`${url}/:language`}
                    exact
                    role={true}
                    component={Platform}
                  />
                  <PrivateRoute
                    path={`${url}/addContent/:language/:packageType/:noOfWeek`}
                    exact
                    role={true}
                    component={AddContentHome}
                  />
                  <PrivateRoute
                    path={`${url}/editContent/:id`}
                    exact
                    role={true}
                    component={PlatformManagementContentEdit}
                  />
                  <PrivateRoute
                    path={`${url}/addGameContent/:packageType/:noOfWeek`}
                    exact
                    role={true}
                    component={AddGameHome}
                  />
                  <PrivateRoute
                    path={`${url}/editGameContentMain/:noOfWeek`}
                    exact
                    role={true}
                    component={GameManagementContentEditMain}
                  />
                  <PrivateRoute
                    path={`${url}/editGameContent/:id`}
                    exact
                    role={true}
                    component={GameManagementContentEdit}
                  />
                </>
              )}
            />
            {/* analytics pages */}
            <Route
              path="/analytics"
              render={({ match: { url } }) => (
                <>
                  <PrivateRoute
                    path={`${url}`}
                    exact
                    role={true}
                    component={Analytics}
                  />
                  <PrivateRoute
                    path={`${url}/quiz/:schoolId/:title/:weekId`}
                    exact
                    role={true}
                    component={WeeklyQuiz}
                  />
                </>
              )}
            />

            {/* <Route path='/analytics' component={Analytics} exact /> */}
          </Switch>
        </div>
      </Router>
    </PlatformManagementProvider>
  );
};

export default App;
