import React, { useContext, useState, useEffect } from 'react'
import TableCell from '@mui/material/TableCell'
import { Button, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { InfoContext } from '../Context/InfoContext'
import { GlobalModal } from '../../components/Modal'
import adminService from '../../services/siteadminService'
import { useDispatch } from 'react-redux'
import { SchoolOutlined } from '@mui/icons-material'

export default function ClassroomCell({ row, match, school }) {
  // const [schoolName, setSchoolName] = useState(
  //   useContext(InfoContext.schoolName)
  // )
  const [teacherList, setTeacherList] = useState([])
  // useEffect(() => {
  //   if (row?.teacher.length > 0) {
  //     setTeacherList(row?.teacher.map((teacherIs) => teacherIs.name))
  //   }
  // }, [row])

  const schoolName = useContext(InfoContext).schoolName
  const schoolId = useContext(InfoContext).schoolId

  const navigation = useHistory()

  const dispatch = useDispatch()

  const InspectOn = (e, id) => {
    // localStorage.setItem('classId', row._id)
    navigation.push({
      pathname: `/dashboard/classroom/${id}/${row.className}`,
      state: {
        id: schoolId,
      },
    })
  }

  const [removeStudentModelState, setRemoveStudentModelState] = useState(false)

  const removeClassroomHandler = (row) => {
    setRemoveStudentModelState(true)
  }

  const closeRemoveStudentModel = () => {
    setRemoveStudentModelState(false)
  }

  const deleteClassroom = async () => {
    await adminService.deleteClassroom(row._id, dispatch)
    await adminService.getShool(school, dispatch)

    closeRemoveStudentModel()
  }

  const calculatePercent = (grades, student = 0) => {
    let total = 0
    grades.length &&
      grades.map((grade) => {
        grade?.studentGrades.map((gradeIs) => {
          total += gradeIs
        })
      })
    return (total / student).toFixed(0)
  }
  return (
    <>
      <TableCell
        style={{ cursor: 'pointer' }}
        onClick={(e) => InspectOn(e, row._id)}
        align='left'
      >
        {console.log('row is', row)}
        {row.className}
      </TableCell>
      {console.log('latest teacher', teacherList)}

      <TableCell align='left'>{row?.student.length}</TableCell>
      <TableCell align='left'> Week 1: Getting Started</TableCell>
      <TableCell align='left'>
        {calculatePercent(row.student, row?.student.length)}
      </TableCell>
      <TableCell align='left'>
        {row?.teacher.map((teacherIs) => teacherIs.name).toString()}
      </TableCell>
      <TableCell align='left'>
        <Typography>
          <button
            className='remove-btn'
            onClick={() => removeClassroomHandler(row)}
          >
            Remove
          </button>
        </Typography>
      </TableCell>

      <GlobalModal
        open={removeStudentModelState}
        close={closeRemoveStudentModel}
        heading=''
      >
        <Typography style={{ width: '390px' }}>
          Are you sure you want to delete this classroom ?
          <br />
          <br />
          All information related to this classroom will be completely deleted !
        </Typography>

        <div className='btn-groups'>
          <button onClick={deleteClassroom} className='export'>
            Confirm
          </button>
          <button className='cancel' onClick={closeRemoveStudentModel}>
            Cancel
          </button>
        </div>
      </GlobalModal>
    </>
  )
}
