export const ADMIN_GET_REGIONS = 'ADMIN_GET_REGIONS'
export const ADMIN_GET_SCHOOLS = 'ADMIN_GET_SCHOOLS'

export const ADMIN_GET_SCHOOL = 'ADMIN_GET_SCHOOL'
export const ADMIN_GET_SCHOOL_TEACHER = 'ADMIN_GET_SCHOOL_TEACHER'
export const ADMIN_GET_SCHOOL_LICENSE = 'ADMIN_GET_SCHOOL_LICENSE'

export const ADMIN_POST_MANAGE_QUESTION_CSV = 'ADMIN_POST_MANAGE_QUESTION_CSV'

export const ADMIN_GET_CLASSROOMS = 'ADMIN_GET_CLASSROOMS'
export const ADMIN_ADD_CLASSROOMS = 'ADMIN_ADD_CLASSROOMS'
export const ADMIN_DELETE_CLASSROOMS = 'ADMIN_DELETE_CLASSROOMS'
export const ADMIN_GET_ALLSTUDENTS_CLASSROOMS =
  'ADMIN_GET_ALLSTUDENTS_CLASSROOMS'
export const ADMIN_GET_TEACHERS = 'ADMIN_GET_TEACHERS'
export const ADMIN_GET_LICENSES = 'ADMIN_GET_LICENSES'
export const ADMIN_GET_CALENDAR_EVENTS = 'ADMIN_GET_CALENDAR_EVENTS'
export const ADMIN_ADD_CALENDAR_EVENTS = 'ADMIN_ADD_CALENDAR_EVENTS'
export const ADMIN_UPDATE_CALENDAR_EVENTS = 'ADMIN_UPDATE_CALENDAR_EVENTS'
export const ADMIN_GET_PLATFORM_MANAGEMENT = 'ADMIN_GET_PLATFORM_MANAGEMENT'
export const ADMIN_GET_DECISIONTREE_QUESTIONS =
  'ADMIN_GET_DECISIONTREE_QUESTIONS'

//MANAGE ACCOUNTS
export const ADMIN_GET_ACCOUNTS_SITEADMIN = 'ADMIN_GET_ACCOUNTS_SITEADMIN'
export const ADMIN_GET_ACCOUNTS_SCHOOLADMIN = 'ADMIN_GET_ACCOUNTS_SCHOOLADMIN'
export const ADMIN_GET_ACCOUNTS_TEACHER = 'ADMIN_GET_ACCOUNTS_TEACHER'
export const ADMIN_GET_ACCOUNTS_STUDENT = 'ADMIN_GET_ACCOUNTS_STUDENT'

//ANALYTICS
export const ADMIN_GET_AVG = 'ADMIN_GET_AVG'
export const ADMIN_GET_MEDIA = 'ADMIN_GET_MEDIA'
export const ADMIN_GET_MEDIA_TEXT = 'ADMIN_GET_MEDIA_TEXT'
export const ADMIN_GET_GAME_PARAMETERS = 'ADMIN_GET_GAME_PARAMETERS'
export const ADMIN_GET_CHARACTER_MOSTPICKED = 'ADMIN_GET_CHARACTER_MOSTPICKED'
export const ADMIN_GET_WEEKLY_PERFORMANCE = 'ADMIN_GET_WEEKLY_PERFORMANCE'
export const ADMIN_GET_SYSTEM_RECORDS = 'ADMIN_GET_SYSTEM_RECORDS'

//GAME CHARACTER
export const ADMIN_GET_GAME_CHARACTERS = 'ADMIN_GET_GAME_CHARACTERS'
export const START_API_CALL = 'START_API_CALL'
export const END_API_CALL = 'END_API_CALL'

//ANALYTICS
export function adminGetGameCharacterMostPicked(payload) {
  return { type: ADMIN_GET_CHARACTER_MOSTPICKED, payload }
}
export function adminGetMedia(payload) {
  return { type: ADMIN_GET_MEDIA, payload }
}
export function adminGetMediaText(payload) {
  return { type: ADMIN_GET_MEDIA_TEXT, payload }
}
export function adminGetAccountSiteadmin(payload) {
  return { type: ADMIN_GET_ACCOUNTS_SITEADMIN, payload }
}
export function adminGetAccountStudent(payload) {
  return { type: ADMIN_GET_ACCOUNTS_SCHOOLADMIN, payload }
}
export function adminGetAccountTeacher(payload) {
  return { type: ADMIN_GET_ACCOUNTS_TEACHER, payload }
}
export function adminGetAccountSchool(payload) {
  return { type: ADMIN_GET_ACCOUNTS_STUDENT, payload }
}
export function adminPostManageQuestionCSV(payload) {
  return { type: ADMIN_POST_MANAGE_QUESTION_CSV, payload }
}
export function adminGetWeeklyPerformance(payload) {
  return { type: ADMIN_GET_WEEKLY_PERFORMANCE, payload }
}
export function adminGetPlatformManagement(payload) {
  return { type: ADMIN_GET_PLATFORM_MANAGEMENT, payload }
}
export function adminGetGameParameters(payload) {
  return { type: ADMIN_GET_GAME_PARAMETERS, payload }
}

export function adminGetAverage(payload) {
  return { type: ADMIN_GET_AVG, payload }
}

export function adminGetSchools(payload) {
  return { type: ADMIN_GET_SCHOOLS, payload }
}

export function adminGetSchool(payload) {
  return { type: ADMIN_GET_SCHOOL, payload }
}
export function adminGetSchoolTeacher(payload) {
  return { type: ADMIN_GET_SCHOOL_TEACHER, payload }
}
export function adminGetSchoolLicense(payload) {
  return { type: ADMIN_GET_SCHOOL_LICENSE, payload }
}

export function adminGetClassrooms(payload) {
  return { type: ADMIN_GET_CLASSROOMS, payload }
}

export function adminAddClassrooms(payload) {
  return { type: ADMIN_ADD_CLASSROOMS, payload }
}

export function adminDeleteClassrooms(payload) {
  return { type: ADMIN_GET_CLASSROOMS, payload }
}

export function adminGetAllStudentsofClass(payload) {
  return { type: ADMIN_GET_ALLSTUDENTS_CLASSROOMS, payload }
}

export function adminGetTeachers(payload) {
  return { type: ADMIN_GET_TEACHERS, payload }
}

export function adminGetLicenses(payload) {
  return { type: ADMIN_GET_LICENSES, payload }
}

export function adminGetRegions(payload) {
  return { type: ADMIN_GET_REGIONS, payload }
}

export function adminGetCalendarEvents(payload) {
  return { type: ADMIN_GET_CALENDAR_EVENTS, payload }
}

export function adminAddCalendarEvents(payload) {
  return { type: ADMIN_ADD_CALENDAR_EVENTS, payload }
}

export function adminUpdateCalendarEvents(payload) {
  return { type: ADMIN_UPDATE_CALENDAR_EVENTS, payload }
}

export function adminGetDecisionTreeQuestions(payload) {
  return { type: ADMIN_GET_DECISIONTREE_QUESTIONS, payload }
}

export function adminGetGameCharacters(payload) {
  return { type: ADMIN_GET_GAME_CHARACTERS, payload }
}

export function adminGetSystemRecords(payload) {
  return { type: ADMIN_GET_SYSTEM_RECORDS, payload }
}

export function beginApiCall() {
  return { type: START_API_CALL }
}
export function endApiCall() {
  return { type: END_API_CALL }
}
