import { Button } from '@mui/material'
import React, { useEffect } from 'react'
import TableMain from '../Table'

const inspectHeadCells = [
  {
    id: 'student',
    label: 'Student',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'email',
    label: 'Registered email',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'ranking',
    label: 'Ranking',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'mean',
    label: 'Mean scores',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'value',
    label: 'Company value',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
    disablePadding: false,
  },
]

export default function InspectClassroom({ resultData, name }) {
  console.log('here is this the command')
  resultData.map((datum, key) => (datum.index = key + 1))
  return (
    <div>
      <div>
        <div
          style={{ float: 'left', marginTop: 30, marginLeft: 5, fontSize: 24 }}
        >
          {name}
        </div>
        <div style={{ float: 'right', marginBottom: 20 }}>
          <button className='school-btn'>Export Report</button>
        </div>
      </div>

      <TableMain
        headCells={inspectHeadCells}
        resultData={resultData}
        topic='studentList'
        // school='abcdef'
      />
    </div>
  )
}
