import React, { useState, useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import adminService from "../../services/siteadminService";
import Box from "@mui/material/Box";
import { Divider } from "@mui/material";
import { consoleError } from "../../utils/helper";
import api from "../../services/api";
import penciledit from "../../assets/pencil-edit.svg";
import plus from "../../assets/plus.svg";
import { useHistory } from "react-router-dom";
import { GlobalModal } from "../../components/Modal";
import ConfirmButton from "../../components/Buttons/ConfirmButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { toast } from "react-toastify";

const ChooseContentLanguage = () => {
  const [availableLanguage, setAvailableLanguage] = useState([]);
  const history = useHistory();

  useEffect(() => {
    fetchLanguage();
  }, []);

  const fetchLanguage = async () => {
    try {
      const resData = await api.get("/api/v1/siteadmin/view/all/language");
      if (resData.ok && resData.data.length > 0) {
        setAvailableLanguage(resData.data);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  // Add language State starts
  const [addLanguageModel, setAddLanguageModel] = useState(false);
  const [lang, setLang] = useState({
    name: "",
    isoCode: "",
  });
  const closeAddLanguageModelHandler = () => {
    setAddLanguageModel(false);
  };

  const languageInputChangeHandler = (e) => {
    const { name, value } = e.target;

    setLang((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const addLanguageHandler = async () => {
    try {
      const res = await api.post("/api/v1/siteadmin/add/language", lang);
      if (res && res.ok) {
        fetchLanguage();
        closeAddLanguageModelHandler();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  // Add language State ends

  const addOrEditHandler = (language) => {
    history.push(`/platformManagement/${language}`);
  };

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="heading-main">
          Content Language
          <Divider sx={{ width: "95%", borderBottomWidth: 2, mt: 1 }} />
        </div>
        <div className="container-page">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}></Box>
            <div className="languageCardMain">
              {availableLanguage && availableLanguage.length > 0
                ? availableLanguage.map((datum, index) => {
                    return (
                      <div
                        className="languageCard"
                        key={index}
                        onClick={() => addOrEditHandler(datum.name)}
                      >
                        <div className="innerDiv">
                          <span>
                            {datum.name}({datum.isoCode})
                          </span>
                          <img src={penciledit} alt="edit" />
                        </div>
                      </div>
                    );
                  })
                : ""}
              <div
                className="languageCard"
                onClick={() => setAddLanguageModel(true)}
              >
                <div className="innerDiv">
                  <span>Add Language</span>
                  <img src={plus} alt="edit" style={{ width: "22px" }} />
                </div>
              </div>
            </div>
          </Box>
        </div>
      </div>
      <GlobalModal
        open={addLanguageModel}
        close={closeAddLanguageModelHandler}
        heading="
      Add new Language"
      >
        <div className="addLanguageModelMain py-4">
          <div className="d-flex flex-column my-2">
            <label htmlFor="userName">Language</label>
            <input
              className="py-1 my-2"
              type="text"
              name="name"
              value={lang.name}
              onChange={(e) => languageInputChangeHandler(e)}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <label htmlFor="userName">ISO Code</label>
            <input
              className="py-1 my-2"
              type="text"
              name="isoCode"
              value={lang.isoCode}
              onChange={(e) => languageInputChangeHandler(e)}
            />
          </div>
          <div className="confirm-close-btns">
            <ConfirmButton>
              <div className="open-btn" onClick={addLanguageHandler}>
                Add
              </div>
            </ConfirmButton>
            <CancelButton>
              <div className="close-btn" onClick={closeAddLanguageModelHandler}>
                Cancel
              </div>
            </CancelButton>
          </div>
        </div>
      </GlobalModal>
    </>
  );
};
export default ChooseContentLanguage;
