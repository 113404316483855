import React, { useEffect, useState } from "react";
import uploadIconWhite from "../../../assets/uploadIconWhite.svg";
import removeIcon from "../../../assets/removeIcon.svg";
import imageIcon from "../../../assets/imageIcon.svg";
import config from "../../../config";
import api from "../../../services/api";
import backImg from "../../../assets/cityBg.png";
import { consoleError } from "../../../utils/helper";

const ShopImageUpload = () => {
  const [addNewScreen, setAddNewScreen] = useState(false);
  const [choosenShopImage, setChooseShopImage] = useState("");
  const [bgImg, setShopImg] = useState("");
  const [uploadingBgImg, setUploadingBgImg] = useState(false);
  const [addingShopImage, setAddingShopImage] = useState(false);
  const [bgId, setBgId] = useState("");
  const [tag, setTag] = useState("");

  const [allShopImage, setAllShopImage] = useState([]);

  useEffect(() => {
    getAllShopImage();
  }, [addNewScreen]);

  const getAllShopImage = async () => {
    try {
      const res = await api.get("/api/v1/siteadmin/all/shop-image");
      if (res && res.ok) {
        setAllShopImage(res.data);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  const uploadShopImage = async (e) => {
    //console.log("reached here");
    setChooseShopImage(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("shopImg", file);
    try {
      setUploadingBgImg(true);
      const res = await api.post(
        `/api/v1/siteadmin/upload/shop-img`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        console.log(res.url);
        setShopImg(res.url);
        setUploadingBgImg(false);
      }
    } catch (error) {
      console.log(error);
      setUploadingBgImg(false);
    }
  };

  const addBgImage = async () => {
    // console.log({ tag, bgImg });
    try {
      if (bgId != "") {
        const res = await api.patch(
          `/api/v1/siteadmin/update/shop-image/${bgId}`,
          {
            tag: tag,
            bgImg: bgImg,
          }
        );
        if (res && res.ok) {
          setAddNewScreen(false);
          clearData();
        }
      } else {
        const res = await api.post("/api/v1/siteadmin/add/shop-image", {
          tag: tag,
          bgImg: bgImg,
        });
        if (res && res.ok) {
          setAddNewScreen(false);
          clearData();
        }
      }
    } catch (err) {
      consoleError(err);
    }
  };

  const deleteImage = async (id) => {
    try {
      const res = await api.delete(
        `/api/v1/siteadmin/delete/backgound-image/${id}`
      );
      if (res && res.ok) {
        getAllShopImage();
      }
    } catch (err) {
      consoleError(err);
    }
  };

  const clearData = () => {
    setTag("");
    setShopImg("");
    setChooseShopImage("");
  };

  const redirectToEditPage = (datum) => {
    setBgId(datum._id);
    setTag(datum.tag);
    setShopImg(datum.bgImg);
    setAddNewScreen(true);
  };

  return (
    <div className="gameBackgroundUploadMain">
      {!addNewScreen ? (
        <>
          <div className="btnDiv">
            <button
              onClick={() => {
                setAddNewScreen(true);
              }}
            >
              <img src={uploadIconWhite} alt="upoadIcon" />
              Add New
            </button>
          </div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {allShopImage && allShopImage.length > 0
              ? allShopImage.map((datum, index) => {
                  return (
                    <div
                      className="displayImage"
                      key={index}
                      onClick={() => redirectToEditPage(datum)}
                    >
                      <div
                        className="removeMedia"
                        style={{ zIndex: 2 }}
                        onClick={() => deleteImage(datum._id)}
                      >
                        <img src={removeIcon} alt="remove" />
                      </div>
                      <img
                        src={
                          datum.bgImg ? config.app.awsBaseUrl + datum.bgImg : ""
                        }
                        alt="bg"
                      />
                    </div>
                  );
                })
              : "No Image"}
          </div>
        </>
      ) : (
        <div className="uploadScreenMain d-flex">
          <div className="leftDiv">
            <div className="inputField">
              <label>Tag</label>
              <input
                type="text"
                name="tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </div>
          </div>
          <div className="right">
            <div className="image-upload">
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="" />
                </label>
                <span>Upload IMAGE</span>

                <input
                  onChange={(e) => uploadShopImage(e)}
                  type="file"
                  class="input-file"
                  id="input_file2"
                  accept="image/png, image/jpeg"
                />

                <span>{choosenShopImage}</span>
                <label className="change-img" htmlFor="input_file2">
                  Browse{" "}
                </label>
              </div>
              {bgImg ? (
                <div>
                  <img
                    width="100px"
                    height="100px"
                    src={config.app.awsBaseUrl + bgImg}
                    alt="img"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="footerBar">
              <button
                disabled={addingShopImage}
                onClick={addBgImage}
                className="create"
              >
                {bgId.length ? "Update" : "Create"}
              </button>
              <button
                onClick={() => {
                  setAddNewScreen(false);
                }}
                className="cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default ShopImageUpload;
