import React, { useState, useEffect } from "react";
import { SetProps, TabPanel } from "../../components/Tabs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Lesson from "../../components/Platform/Lesson/lesson";
import Game from "../../components/Platform/Game/game";
import Database from "../../components/Platform/Database/database";
import { Divider } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import adminService from "../../services/siteadminService";
import { useSelector, useDispatch } from "react-redux";
import api from "../../services/api";
import { usePlatform } from "../../context/PlatformManagement";
import config from "../../config";
import GlobalGameMgmt from "../../components/Platform/GlobalGameManagement/GlobalGameMgmt";
import { useParams } from "react-router-dom";

export default function Platform() {
  const { language } = useParams();
  const { value, setValue } = usePlatform();

  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [LoadCharacters, setLoadCharacters] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  // ADD NEW MODEL STATE STARTS

  const gameCharacters = useSelector((state) => state.siteadmin.gameCharacters);

  useEffect(() => {
    adminService.getGameCharacters(dispatch);
  }, [LoadCharacters]);

  console.log(gameCharacters);

  const [id1, setId1] = useState("");
  const [id2, setId2] = useState("");
  const [id3, setId3] = useState("");

  const [content1, setContent1] = useState([]);
  const [content2, setContent2] = useState([]);
  const [content3, setContent3] = useState([]);

  useEffect(() => {
    const getPackageIds = async () => {
      setLoading(true);
      try {
        const res = await api.get(
          `api/v1/siteadmin/getDefaultClassroomByPackageType/${language}/4`
        );
        const res1 = await api.get(
          `api/v1/siteadmin/getDefaultClassroomByPackageType/${language}/8`
        );
        const res2 = await api.get(
          `api/v1/siteadmin/getDefaultClassroomByPackageType/${language}/12`
        );

        if (res && res.ok) {
          setId1(res.data._id);
        }
        if (res1 && res1.ok) {
          setId2(res1.data._id);
        }
        if (res2 && res2.ok) {
          setId3(res2.data._id);
        }
        if (res && res.ok && res1 && res1.ok && res2 && res2.ok) {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    getPackageIds();
  }, []);

  useEffect(() => {
    const getContents = async () => {
      setLoading(true);
      try {
        const res = await api.get(
          `api/v1/siteadmin/getClassContent/${id1}/${language}`
        );
        const res1 = await api.get(
          `api/v1/siteadmin/getClassContent/${id2}/${language}`
        );
        const res2 = await api.get(
          `api/v1/siteadmin/getClassContent/${id3}/${language}`
        );

        if (res && res.ok) {
          setContent1(res.data);
        }
        if (res1 && res1.ok) {
          setContent2(res1.data);
        }
        if (res2 && res2.ok) {
          setContent3(res2.data);
        }
        if (res && res.ok && res1 && res1.ok && res2 && res2.ok) {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (id1.length && id2.length && id3.length) {
      getContents();
    }
  }, [id1, id2, id3, refresh]);

  useEffect(() => {
    getBackgroundImages();
  }, []);

  // Preload game background image
  const getBackgroundImages = async () => {
    try {
      const res = await api.get("/api/v1/student/all/backgound-image");
      if (res && res.ok) {
        for (var i = 0; i < res.data.length; i++) {
          let img = new Image();
          img.src = `${config.app.apiUrl}/api/uploads/admin/character/${res.data[i].bgImg}`;
          window[`${res.data[i].bgImg}`] = img;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  // preload ends

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="heading-main">
          Platform Management
          <Divider sx={{ width: "95%", borderBottomWidth: 2, mt: 1 }} />
        </div>
        <div className="container-page">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                sx={{
                  "& .MuiButtonBase-root": {
                    backgroundColor: "#DADADA ",
                    width: 1007,
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#EF5B2C",
                    color: "white !important",
                    borderRadius: 1,
                  },

                  "& .MuiTabs-indicator": {
                    borderBottomColor: "#EF5B2C !important",
                  },
                }}
                value={value}
                onChange={handleChange}
              >
                <Tab label="Lesson Management" {...SetProps(0)} />
                {/* <Tab label="Game Management" {...SetProps(1)} /> */}
                <Tab label="Game Management" {...SetProps(1)} />
                <Tab label="Database" {...SetProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Lesson
                content1={content1}
                content2={content2}
                content3={content3}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <Game
                content1={content1}
                content2={content2}
                content3={content3}
              />
            </TabPanel> */}
            <TabPanel value={value} index={1}>
              <GlobalGameMgmt />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Database
                LoadCharacters={LoadCharacters}
                setLoadCharacters={setLoadCharacters}
                gameCharacters={gameCharacters}
              />
            </TabPanel>
          </Box>
        </div>
      </div>
    </>
  );
}
