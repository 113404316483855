import React from "react";
import TableCell from "@mui/material/TableCell";
import { Button, Typography } from "@mui/material";
import { format, formatDistanceToNow } from "date-fns";
import api from "../../services/api";
import { toast } from "react-toastify";

export default function LicenseCell({ row, school }) {
  const sendOtpHandler = async () => {
    try {
      const res = await api.get(`/api/v1/siteadmin/resend/otp/${school}`);
      if (res && res.ok) {
        toast.success(res.message);
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <TableCell align="left">
        {format(new Date(row?.expiryDate), "yyyy-MM-dd")}{" "}
      </TableCell>
      <TableCell align="left">{row.packageType}</TableCell>
      <TableCell align="left">
        {format(new Date(row?.expiryDate), "yyyy-MM-dd")}{" "}
      </TableCell>
      <TableCell align="left">Package Type{row?.packageType}</TableCell>
      <TableCell align="left">
        <Typography>
          {/* <button className="remove-btn">Details</button> */}
          <button
            className="remove-btn"
            style={{ width: "auto", marginLeft: "5px" }}
            onClick={sendOtpHandler}
          >
            Send OTP
          </button>
        </Typography>
      </TableCell>
    </>
  );
}
