import React, { useState } from "react";
import TableCell from "@mui/material/TableCell";
import { Button, Typography } from "@mui/material";
import { GlobalModal } from "../../components/Modal";
import ConfirmButton from "../../components/Buttons/ConfirmButton";
import CancelButton from "../../components/Buttons/CancelButton";
import warning from "../../assets/warning.svg";

export default function TeacherInspectCell({ row }) {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const [value, setValue] = React.useState(0);

  const openModal = () => {
    setOpen(true);
  };

  const handleChange = (event, newValue) => {};
  const closeModal = () => {
    setOpen(false);

    setClose(true);
  };

  const [removeClassroomModelState, setRemoveClassroomModelState] =
    useState(false);

  const removeClassroomHandler = (row) => {
    console.log(row);
    setRemoveClassroomModelState(true);
  };

  const closeRemoveClassroomModel = () => {
    setRemoveClassroomModelState(false);
  };

  const Modal = () => (
    <GlobalModal open={open} close={close} heading="Reassignment of classroom">
      <div className="content">
        <div className="form-content">
          <input type="checkbox" name="" id="" />
          <span>Assign to one teacher</span>
        </div>
        <div className="form-content">
          <input type="checkbox" name="" id="" />
          <span>Assign to different teacher</span>
        </div>
      </div>
      <div className="warning">
        <div className="warning-left">
          <img src={warning} alt="Warning" />
        </div>
        <div className="warning-right">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum,
          mattis ultrices vitae, ipsum. Vitae venenatis enim sit accumsan
          pretium at enim faucibus sed. Pellentesque senectus nulla luctus id
          arcu.
        </div>
      </div>

      <div className="confirm-close-btns">
        <ConfirmButton>
          <div className="open-btn">Confirm</div>
        </ConfirmButton>
        <CancelButton>
          <div className="close-btn" onClick={closeModal}>
            Cancel
          </div>
        </CancelButton>
      </div>
    </GlobalModal>
  );
  return (
    <>
      <TableCell align="left">{row.classname}</TableCell>
      <TableCell align="left">{row.size}</TableCell>
      <TableCell align="left">{row.progress}</TableCell>
      <TableCell align="left">{row.mean}</TableCell>

      <TableCell align="left">
        <Typography>
          <button onClick={openModal} className="large-btn inspect-btn">
            Reassign teacher
          </button>
        </Typography>
      </TableCell>
      <TableCell align="left">
        <Typography>
          <button
            className="large-btn inspect-btn"
            onClick={() => removeClassroomHandler(row)}
          >
            Delete classroom
          </button>
        </Typography>
      </TableCell>
      <Modal />

      <GlobalModal
        open={removeClassroomModelState}
        close={closeRemoveClassroomModel}
        heading=""
      >
        <Typography style={{ width: "390px" }}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum,
          mattis ultrices vitae, ipsum. Vitae venenatis enim sit accumsan
          pretium at enim faucibus sed. Pellentesque senectus nulla luctus id
          arcu. .
        </Typography>

        <div className="btn-groups">
          <button className="export">Confirm</button>
          <button className="cancel" onClick={closeRemoveClassroomModel}>
            Cancel
          </button>
        </div>
      </GlobalModal>
    </>
  );
}
