import {
  ADMIN_GET_REGIONS,
  ADMIN_GET_SCHOOLS,
  ADMIN_GET_SCHOOL,
  ADMIN_GET_MEDIA_TEXT,
  ADMIN_GET_SCHOOL_TEACHER,
  ADMIN_GET_SCHOOL_LICENSE,
  ADMIN_GET_CLASSROOMS,
  ADMIN_POST_MANAGE_QUESTION_CSV,
  ADMIN_GET_ACCOUNTS_SITEADMIN,
  ADMIN_GET_MEDIA,
  ADMIN_GET_ACCOUNTS_SCHOOLADMIN,
  ADMIN_GET_ACCOUNTS_TEACHER,
  ADMIN_GET_ACCOUNTS_STUDENT,
  ADMIN_GET_TEACHERS,
  ADMIN_GET_PLATFORM_MANAGEMENT,
  ADMIN_GET_LICENSES,
  ADMIN_DELETE_CLASSROOMS,
  ADMIN_GET_ALLSTUDENTS_CLASSROOMS,
  ADMIN_ADD_CLASSROOMS,
  ADMIN_GET_CALENDAR_EVENTS,
  ADMIN_GET_WEEKLY_PERFORMANCE,
  ADMIN_ADD_CALENDAR_EVENTS,
  ADMIN_UPDATE_CALENDAR_EVENTS,
  ADMIN_GET_DECISIONTREE_QUESTIONS,
  ADMIN_GET_GAME_PARAMETERS,
  ADMIN_GET_CHARACTER_MOSTPICKED,
  ADMIN_GET_AVG,
  ADMIN_GET_GAME_CHARACTERS,
  ADMIN_GET_SYSTEM_RECORDS,
  START_API_CALL,
  END_API_CALL,
} from '../actions/siteadminActions'

const initialState = {
  regions: [],
  schools: [],
  school: {},
  school_teacher: [],
  school_license: [],
  classrooms: [],
  allstudentsofclass: [],
  teachers: [],
  licenses: [],
  calendar_events: [],
  decision_questions: [],
  weekly_performance: [],
  platform_management: [],
  site_admin: [],
  school_admin: [],
  teacher_admin: [],
  student_admin: [],
  avg: 0,
  media: [],
  mediaText: [],
  gameParameters: {},
  characterMostPicked: [],
  companyValue: 0,
  companyCash: 0,
  companyReputation: 0,
  companySatisfaction: 0,
  gameCharacters: [],
  systemRecords: [],
  apiStatus: 0,
}

export default function siteadminReducer(state = initialState, action) {
  switch (action.type) {
    // regions
    case ADMIN_GET_SCHOOLS: {
      return {
        ...state,
        schools: [...action.payload],
      }
    }
    case ADMIN_GET_MEDIA: {
      return {
        ...state,
        media: [...action.payload],
      }
    }
    case ADMIN_GET_MEDIA_TEXT: {
      return {
        ...state,
        mediaText: [...action.payload],
      }
    }
    case ADMIN_GET_ACCOUNTS_SITEADMIN: {
      return {
        ...state,
        site_admin: [...action.payload],
      }
    }
    case ADMIN_GET_ACCOUNTS_SCHOOLADMIN: {
      return {
        ...state,
        school_admin: [...action.payload],
      }
    }
    case ADMIN_GET_ACCOUNTS_TEACHER: {
      return {
        ...state,
        teacher_admin: [...action.payload],
      }
    }
    case ADMIN_GET_ACCOUNTS_STUDENT: {
      return {
        ...state,
        student_admin: [...action.payload],
      }
    }
    case ADMIN_GET_WEEKLY_PERFORMANCE: {
      return {
        ...state,
        weekly_performance: [...action.payload],
      }
    }
    case ADMIN_POST_MANAGE_QUESTION_CSV: {
      return {
        ...state,
      }
    }
    case ADMIN_GET_PLATFORM_MANAGEMENT: {
      return {
        ...state,
        platform_management: [...action.payload],
      }
    }
    case ADMIN_GET_SCHOOL: {
      return {
        ...state,
        school: [...action.payload],
      }
    }
    case ADMIN_GET_SCHOOL_TEACHER: {
      return {
        ...state,
        school_teacher: [...action.payload],
      }
    }
    case ADMIN_GET_SCHOOL_LICENSE: {
      return {
        ...state,
        school_license: [...action.payload],
      }
    }
    case ADMIN_GET_CHARACTER_MOSTPICKED: {
      return {
        ...state,
        characterMostPicked: [...action.payload],
      }
    }
    case ADMIN_GET_GAME_PARAMETERS: {
      return {
        ...state,
        gameParameters: action.payload,
      }
    }
    case ADMIN_GET_AVG: {
      return {
        ...state,
        avg: action.payload,
      }
    }

    case ADMIN_GET_CLASSROOMS: {
      return {
        ...state,
        classrooms: [...action.payload],
      }
    }

    case ADMIN_ADD_CLASSROOMS: {
      const school = [...state.school]
      school.push(action.payload)
      return {
        ...state,
        school: school,
      }
    }

    case ADMIN_GET_ALLSTUDENTS_CLASSROOMS: {
      return {
        ...state,
        allstudentsofclass: [...action.payload],
      }
    }

    case ADMIN_DELETE_CLASSROOMS: {
      const classrooms = [...state.classrooms]
      let index = classrooms.findIndex((item) => item._id === action.payload)
      classrooms.splice(index, 1)
      return {
        ...state,
        classrooms: classrooms,
      }
    }

    case ADMIN_GET_TEACHERS: {
      return {
        ...state,
        teachers: [...action.payload],
      }
    }

    case ADMIN_GET_LICENSES: {
      return {
        ...state,
        licenses: [...action.payload],
      }
    }

    case ADMIN_GET_REGIONS: {
      return {
        ...state,
        regions: [...action.payload],
      }
    }

    case ADMIN_GET_CALENDAR_EVENTS: {
      return {
        ...state,
        calendar_events: [...action.payload],
      }
    }

    case ADMIN_ADD_CALENDAR_EVENTS: {
      const calendar_events = [...state.calendar_events]
      calendar_events.push(action.payload)
      return {
        ...state,
        calendar_events: calendar_events,
      }
    }

    case ADMIN_UPDATE_CALENDAR_EVENTS: {
      const calendar_events = [...state.calendar_events]
      let index = calendar_events.findIndex(
        (item) => item._id === action.payload._id
      )
      calendar_events[index] = action.payload
      return {
        ...state,
        calendar_events: calendar_events,
      }
    }

    case ADMIN_GET_DECISIONTREE_QUESTIONS: {
      return {
        ...state,
        decision_questions: [...action.payload],
      }
    }

    case ADMIN_GET_GAME_CHARACTERS: {
      return {
        ...state,
        gameCharacters: [...action.payload],
      }
    }

    case ADMIN_GET_SYSTEM_RECORDS: {
      return {
        ...state,
        systemRecords: [...action.payload],
      }
    }

    default:
      return state
  }
}
