import { Button } from '@mui/material'
import React from 'react'

export default function CancelButton(props) {
  return (
    <button type='button' style={{ border: 'none', background: 'white' }}>
      {props.children}
    </button>
  )
}
