import { combineReducers } from 'redux'
import siteadminReducer from './siteadminReducer'
import loadingReducer from './loadingReducer'

const appReducer = combineReducers({
  siteadmin: siteadminReducer,
  loading: loadingReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT_USER') {
    state = undefined
  }

  return appReducer(state, action)
}
export default rootReducer
