import React, { useState, useEffect, useRef } from 'react'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

export const options2 = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    yAxes: {
      display: true,
      ticks: {
        display: false,
      },
      gridLines: {
        display: true,
        zeroLineColor: 'transparent',
      },
      zeroLineColor: 'transparent',
    },
    xAxes: {
      display: false,
      ticks: {
        display: false,
      },
      gridLines: {
        display: true,
        zeroLineColor: 'transparent',
      },
    },
  },

  plugins: {
    legend: {
      position: 'bottom',
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
  },
}

const labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

const data = {
  labels,
  datasets: [{
    label: 'Dataset 1',
    borderColor: "#ff6c23",
    borderWidth: 2,
    data: [10, 15, 20, 5, 30, 150, 90, 20, 70, 5],
    fill: true,
    tension: 0.5,
  }]
}


function LineChart() {
  const lineRef = useRef(null)
  const [chartData, setChartData] = useState({
    datasets: [],
  });

  const createBackgroundGradient = (ctx, area) => {

    const gradient = ctx.createLinearGradient(0, area.bottom, area.width, area.height);

    gradient.addColorStop(0, 'rgba(250,174,50,1)');
    gradient.addColorStop(0.5521, 'rgba(250,174,50,0)');

    return gradient
  }

  useEffect(() => {
    const chart = lineRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map((dataset) => {
        return {
          ...dataset,
          backgroundColor: createBackgroundGradient(chart.ctx, chart.chartArea),
        }
      })
    }
    setChartData(chartData)
  }, []);

  if (!chartData) {
    return <div>Loading...</div>
  }
  return (
    <Line
      options={options2}
      data={chartData}
      ref={lineRef}
      id="line-chart-analytics"
    />
  )
}

export default LineChart