import { Button } from "@mui/material";
import { InfoContext } from "../Context/InfoContext";
import React, { useState, useContext, useEffect } from "react";
import TableMain from "../Table";
import { useHistory } from "react-router-dom";

function createData(renewdate, type, expdate, code) {
  return {
    renewdate,
    type,
    expdate,
    code,
  };
}

// const resultData = [
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
//   createData('12 Dec 2014', '12 week', '11 March 2024', 'ad3mefcr55'),
// ]

const headCells = [
  {
    id: "renewdate",
    label: "renewdate",
    numeric: false,
    disablePadding: false,
  },

  { id: "type", label: "Package type", numeric: false, disablePadding: false },
  {
    id: "expdate",
    label: "Expiry date",
    numeric: false,
    disablePadding: false,
  },
  { id: "code", label: "License code", numeric: false, disablePadding: false },

  {
    id: "actions",
    label: "Actions",
    numeric: false,
    disablePadding: false,
  },
];

export default function License({ resultData, school }) {
  const navigation = useHistory();
  const schoolName = useContext(InfoContext).schoolName;

  const InspectOn = (e) => {
    navigation.push({
      pathname: `/dashboard/content/${schoolName}`,
      state: {
        name: e.target.textContent,
        school: schoolName,
      },
    });
  };
  return (
    <div>
      <div style={{ float: "right", marginBottom: 20 }}>
        <button onClick={InspectOn} className="school-btn">
          Languages / Content Management
        </button>
      </div>
      <TableMain
        headCells={headCells}
        resultData={resultData}
        topic="license"
        school={school}
      />
    </div>
  );
}
