import React, { useState, useEffect } from 'react'
import { GlobalModal } from '../Modal'

const Progress = ({ total, value }) => {
  return (
    <div style={{
      width: `${(value / total) * 100}%`,
      height: '1.2rem',
      backgroundColor: '#EF5B2C',
      borderRadius: '1.2rem'
    }}>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', color: 'white', fontSize: '0.7em', position: 'relative', top: '10%' }}>
        {parseInt((value / total) * 100) + '%'}
      </div>
    </div >
  )
}

function BoxModal({ open, close, title, data }) {

  const [total, setTotal] = useState()

  useEffect(() => {
    if (Array.isArray(data)) {
      let sum = 0
      data.forEach((item) => {
        sum += item.noOfPicks
      })
      setTotal(sum)
    }
  }, [data])


  return (
    <GlobalModal heading={title} open={open} close={close}>
      <div style={{ marginTop: '1rem' }} >
        {data?.map((item, idx) => {
          return (
            <div key={idx} style={{
              paddingTop: '1rem',
              paddingBottom: '1rem',
            }} >
              <div>
                <div style={{ fontSize: '0.6em' }} >{item.name}</div>
                <Progress variant="determinate" total={total} value={item?.noOfPicks || 0} />
              </div>
            </div>
          )
        })}
      </div>

      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '2rem'
      }} >
        <button className='btn-analytics' onClick={close}>
          Back
        </button>
      </div>
    </GlobalModal>
  )
}

export default BoxModal