import React from "react";
import TableCell from "@mui/material/TableCell";
import config from "../../config";
import { formatDistance } from "date-fns";

export default function AnalyticsSystemCell({ row }) {
  return (
    <>
      <TableCell align="left" sx={{ fontWeight: "600 !important" }}>
        {row?.name}
      </TableCell>
      <TableCell align="left">{row?.file}</TableCell>
      {/* <TableCell align="left">{(row?.filesize / 1000).toFixed(2)} KB</TableCell> */}
      {/* <TableCell align="left">
        {formatDistance(new Date(row?.createdAt), new Date(), {
          addSuffix: true,
        })}
      </TableCell> */}
      <TableCell sx={{ textDecorationLine: "underline" }} align="left">
        <a
          href={config.app.apiUrl + "/api/" + row?.file}
          style={{ color: "red" }}
        >
          Download
        </a>
      </TableCell>
    </>
  );
}
