import React from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";

const BreadCrumbList = (props) => {
  return (
    <div className='breadcrumb-outer'>
      {props.values.map((item, key) => (
        <Link to={item.to}>
          <div className='breadcrumb-title' key={key}>
            {key !== 0 && <NavigateNextIcon />} {item.name}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default BreadCrumbList;
