import React, { useState, useEffect, forwardRef } from 'react'
import FullCalendar, { formatDate } from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import listPlugin from '@fullcalendar/list'
import timeGridPlugin from '@fullcalendar/timegrid'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import edit from '../../assets/edit.svg'
import close from '../../assets/close.svg'
import square from '../../assets/square.svg'
import calendar from '../../assets/calendar.svg'
import time from '../../assets/time.svg'
import AlignLeft from '../../assets/AlignLeft.svg'
import blackDot from '../../assets/blackDot.svg'
import brownDot from '../../assets/brownDot.svg'
import siteadminService from '../../services/siteadminService'
import Sidebar from '../../components/Sidebar'
import { format } from 'date-fns'

import arrow from '../../assets/arrow.svg'
import Checkbox from '@mui/material/Checkbox'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useDispatch, useSelector } from 'react-redux'
import { jsPDF } from 'jspdf'

const Calendar = () => {
  const generatePdf = () => {
    const option = {
      orientation: 'landscape',
      unit: 'pt',
      format: 'a2',
    }
    const report = new jsPDF(option)

    document.getElementsByClassName('fc-button-group')[0].style.visibility =
      'hidden'
    document.getElementsByClassName('fc-button-group')[1].style.visibility =
      'hidden'

    report.html(document.querySelector('#calendar')).then(() => {
      report.save('calendar.pdf')
      document.getElementsByClassName('fc-button-group')[0].style.visibility =
        'visible'
      document.getElementsByClassName('fc-button-group')[1].style.visibility =
        'visible'
    })
  }
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button className='example-custom-input' onClick={onClick} ref={ref}>
      {value}
    </button>
  ))
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  // const Modal = () => {
  //   return (
  //     <Modal
  //       open={open}
  //       onClose={handleClose}
  //       aria-labelledby='modal-modal-title'
  //       aria-describedby='modal-modal-description'
  //     >
  //       <Box sx={style}>kfdg</Box>
  //     </Modal>
  //   )
  // }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',

    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '18px 12px 10px 12px',
    width: '225px',
    height: '273px',

    /* Surface */

    background: '#FFFFFF',
    /* /Border */

    border: '1px solid #E8E8E8',

    /* Shadows/Popover [50px] */

    boxShadow: '0px 24px 50px rgba(88, 88, 88, 0.25)',
    borderRadius: '8px',
  }
  const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',

    transform: 'translate(-50%, -50%)',
    width: '416px',
    // height: '376.45px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '18px 12px 10px 12px',
    paddingBottom: '35px',

    // width: '225px',
    // height: '376px',

    /* Surface */

    background: '#FFFFFF',
    /* /Border */

    border: '1px solid #E8E8E8',

    /* Shadows/Popover [50px] */

    boxShadow: '0px 24px 50px rgba(88, 88, 88, 0.25)',
    borderRadius: '8px',
  }

  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [open2, setOpen2] = React.useState(false)

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const handleClose1 = () => setOpen1(false)
  const handleClose2 = () => setOpen2(false)

  const [eventTitle, setEventTitle] = useState()
  const [newEventTitle, setNewEventTitle] = useState()
  const [eventStartDate, setEventStartDate] = useState()
  const [eventEndDate, setEventEndDate] = useState()
  const [title, setTitle] = useState()

  const editEvent = () => {
    setOpen1(true)
    setOpen(false)
    setOpen2(false)
  }

  const handleDateClick = (arg) => {
    const curTime = new Date().getTime()
    const selectedTime = new Date(arg?.dateStr).getTime() - 1
    const curDate = format(new Date(), 'yyyy-MM-dd')
    if (curDate !== arg?.dateStr) {
      if (selectedTime < curTime) return
    }
    setOpen2(true)
    setOpen(false)
    setOpen1(false)
  }

  const allevents = useSelector((state) => state.siteadmin.calendar_events)

  const stateEvents =
    // allevents.length > 0 &&
    allevents &&
    allevents.length &&
    allevents.map((res) => {
      return {
        id: res._id,
        title: res.eventName,
        start: res.startDate,
        description: res.description,
        end: res.endDate,
        editable: true,
      }
    })
  // const [events, setEvents] = useState(stateEvents)

  const [eventId, setEventId] = useState()

  const eventInfo = (info) => {
    let clickedEvent = stateEvents.filter(
      (res) => info.event._def.publicId === res.id
    )
    setEventId(info.event._def.publicId)
    setEventStartDate(clickedEvent[0].start)
    setEventEndDate(clickedEvent[0].end)
    setEventTitle(clickedEvent[0].title)
    setOpen(true)
  }

  const dispatch = useDispatch()

  const [isLoaded, setLoaded] = useState(false)
  useEffect(() => {
    async function getAllEvents() {
      try {
        siteadminService.getCalendarEvents(dispatch)
      } catch (error) {
        console.error(error)
      }
    }

    getAllEvents()
    setLoaded(true)
  }, [])

  const updateEvent = () => {
    siteadminService.updateCalendarEvents(
      eventId,
      {
        eventName: newEventTitle,
        ...stateEvents.description,
        startDate: eventStartDate,
        endDate: eventEndDate,
      },
      dispatch
    )
    setOpen1(false)
  }

  const [addtitlevalue, setTitlevalue] = useState()
  const [addstartvalue, setstartvalue] = useState()
  const [addendvalue, setendvalue] = useState()

  const addEvent = () => {
    siteadminService.addCalendarEvents(
      {
        eventName: addtitlevalue,
        startDate: addstartvalue,
        endDate: addendvalue,
        title: title,
      },
      dispatch
    )
    setOpen2(false)
  }
  const getEventDay = (date) => {
    const weekday = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]

    // var newDate = new Date(
    //   date.getTime() + date.getTimezoneOffset() * 60 * 1000
    // )

    // var offset = date.getTimezoneOffset() / 60
    // var hours = date.getHours()

    // newDate.setHours(hours - offset)

    // return newDate
  }
  return (
    <>
      <Sidebar />

      <div className='main calendar'>
        <div className='heading-main-calendar'>
          <span>Calendar Management</span>
          <button onClick={generatePdf}>Export</button>
        </div>
        <div className='border-bottom'></div>
        <div className='calendar-cover' id='calendar'>
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            initialView='dayGridMonth'
            dateClick={handleDateClick}
            events={stateEvents}
            eventClick={eventInfo}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
          />
          {/* {open1 && ( */}
          <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style1}>
              <div className='header-edit'>
                <span>{eventTitle}</span>{' '}
                {/* <img onClick={editEvent} src={edit} alt='Edit' /> */}
                <img onClick={handleClose1} src={close} alt='Close' />
              </div>
              <div className='content'>
                <div className='content-group'>
                  <img src={AlignLeft} alt='Align' />
                  <textarea
                    className='text-area'
                    name=''
                    id=''
                    cols='30'
                    rows='10'
                    value={newEventTitle}
                    onChange={(e) => setNewEventTitle(e.target.value)}
                    defaultValue={eventTitle}
                  ></textarea>
                </div>
                <div className='content-group'>
                  <img src={time} alt='Align' />
                  <div className='date-pick'>
                    <div className='date-pick-1'>
                      <DatePicker
                        selected={startDate}
                        dateFormat='hh:mm dd.MM.yyyy'
                        onChange={(date) => setStartDate(date)}
                        showTimeInput
                        // customInput={<ExampleCustomInput />}
                      />
                      <img src={arrow} alt={arrow} />
                    </div>
                    <div className='date-pick-1'>
                      <DatePicker
                        selected={endDate}
                        dateFormat='h:mm dd.MM.yyyy'
                        onChange={(date) => setEndDate(date)}
                        showTimeInput

                        // customInput={<ExampleCustomInput />}
                      />
                      <img src={arrow} alt={arrow} />
                    </div>
                  </div>
                </div>
                <div className='content-group'>
                  <img src={calendar} alt='calendar' />
                  <select name='' id=''>
                    <option value=''>
                      {/* <img src={blackDot} alt='dot' /> */}
                      Select Publish Time
                    </option>
                    <option value=''>
                      {/* <img src={brownDot} alt='' />  */}
                      Specific Schools
                    </option>
                    <option value=''>Specific Reasons</option>
                  </select>
                </div>
                <div className='mass-announcement'>
                  <Checkbox
                    sx={{
                      '&.Mui-checked': {
                        color: '#414141',
                      },
                    }}
                  />
                  <span style={{ marginTop: '7px' }}>Mass Announcement</span>
                </div>
                <div className='create-outer'>
                  <button onClick={updateEvent} className='create-btn'>
                    Create
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={open2}
            onClose={handleClose2}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style1}>
              <div className='header-edit'>
                <input
                  type='text'
                  placeholder='Title'
                  id='calendar-modal-title'
                  onChange={(e) => setTitle(e.target.value)}
                />
                <img onClick={handleClose2} src={close} alt='Close' />
              </div>
              <div className='content'>
                <div className='content-group'>
                  <img src={AlignLeft} alt='Align' />
                  <textarea
                    className='text-area'
                    name=''
                    id=''
                    cols='30'
                    rows='10'
                    value={addtitlevalue}
                    onChange={(e) => setTitlevalue(e.target.value)}
                  ></textarea>
                </div>
                <div className='content-group'>
                  <img src={time} alt='Align' />
                  <div className='date-pick'>
                    <div className='date-pick-1'>
                      <DatePicker
                        selected={addstartvalue}
                        dateFormat='hh:mm dd.MM.yyyy'
                        onChange={(date) => setstartvalue(date)}
                        showTimeInput
                        // customInput={<ExampleCustomInput />}
                      />
                      <img src={arrow} alt={arrow} />
                    </div>
                    <div className='date-pick-1'>
                      <DatePicker
                        selected={addendvalue}
                        dateFormat='h:mm dd.MM.yyyy'
                        onChange={(date) => setendvalue(date)}
                        showTimeInput

                        // customInput={<ExampleCustomInput />}
                      />
                      <img src={arrow} alt={arrow} />
                    </div>
                  </div>
                </div>
                <div className='content-group'>
                  <img src={calendar} alt='calendar' />
                  <select name='' id=''>
                    <option value=''>Select Publish Time</option>
                    <option value='12:00'>12:00</option>
                    <option value='1:00'>1:00</option>
                    <option value='2:00'>2:00</option>
                    <option value='3:00'>3:00</option>
                    <option value='4:00'>4:00</option>
                    <option value='5:00'>5:00</option>
                    <option value='6:00'>6:00</option>
                    <option value='7:00'>7:00</option>
                    <option value='8:00'>8:00</option>
                    <option value='9:00'>9:00</option>
                    <option value='10:00'>10:00</option>
                    <option value='11:00'>11:00</option>
                    <option value='12:00'>12:00</option>
                  </select>
                </div>
                <div className='mass-announcement'>
                  <Checkbox
                    sx={{
                      '&.Mui-checked': {
                        color: '#414141',
                      },
                    }}
                  />
                  <span style={{ marginTop: '7px' }}>Mass Announcement</span>
                </div>
                <div className='create-outer'>
                  <button onClick={addEvent} className='create-btn'>
                    Create
                  </button>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <div className='header'>
                <span>{getEventDay(eventStartDate)}</span>{' '}
                <div className='header-inner'>
                  <img onClick={editEvent} src={edit} alt='Edit' />
                  <img onClick={handleClose} src={close} alt='Close' />
                </div>
              </div>
              <div className='event-top'>
                <div className='event-top-inner'>
                  <span>{eventTitle}</span>
                  <span>10:00</span>
                </div>
              </div>
              <div className='content'>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quam
                ullamcorper nibh purus posuere congue ullamcorper vel. Magnis
                rutrum ut imperdiet vestibulum, arcu, magna morbi pretium.
                Mattis auctor semper a duis mus sagittis. Morbi.
              </div>
            </Box>
          </Modal>
        </div>

        {/* {open && <Modal />} */}
      </div>
    </>
  )
}

export default Calendar
