import { Button } from "@mui/material";
import React from "react";

export default function ConfirmButton(props) {
  return (
    <button style={{ border: "none", background: "white" }}>
      {props.children}
    </button>
  );
}
