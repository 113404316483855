import React from 'react'
import TableCell from '@mui/material/TableCell'
export default function LeaderboardCell({ row }) {
  console.log('row is', row)
  return (
    <>
      <TableCell align='left'>{row?.rank}</TableCell>
      <TableCell align='left'>{row?.schoolId?.name}</TableCell>
      <TableCell align='left'>{row?.name}</TableCell>
      <TableCell align='left'>
        {row?.totalCampnayValuation.toFixed(0)}
      </TableCell>
      <TableCell align='left'>
        {row?.totalcompanyReputation.toFixed(0)}
      </TableCell>
      <TableCell align='left'>{row?.totalemployNumber.toFixed(0)}</TableCell>
      <TableCell align='left'>{row?.totalemploySatisfaction.toFixed(0)}</TableCell>
      <TableCell align='left'>{row?.totalCash.toFixed(0)}</TableCell>
    </>
  )
}
